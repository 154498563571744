import { SyncOutlined } from "@ant-design/icons";
import { getCusQuery } from "@/utils/storage";
import { Popover } from "antd";
import { CUS_CODE_LIST } from "@/constant";

const HomeUserDetail = (props) => {
  // Init Data
  let { gather = {}, handleLink } = props;

  const TEMP = {
    100: "钻卡",
    200: "益购",
    300: "虎族",
    400: "伙伴"
  };

  let ucMvasList = [
    {
      addServicesTypeName: "尊享",
    },
    {
      addServicesTypeName: "读书",
    },
    {
      addServicesTypeName: "MVP",
    },
    {
      addServicesTypeName: "明星",
    },
  ];

  let { cc_platform_name, cc_source_name, cc_source_code } = getCusQuery();
  let { platform, ucMvasDtoList = [], dataTicketList = [] } = gather || {};

  // Tickets available tea
  dataTicketList = dataTicketList.filter((item) => item.status === 100);

  let platformShow =
    cc_platform_name && cc_platform_name.indexOf(TEMP[platform]) !== -1;

  let popover = {
    refresh: "刷新",
  };

  let AppName = TEMP[platform];

  // 暂时自定义虎族俱乐部/益伙伴逻辑
  console.log("gather ==>", platform, cc_source_code, cc_platform_name, platformShow);
  let Huzu = CUS_CODE_LIST.includes(parseInt(cc_source_code));
  if(Huzu) {
    let APP = {
      700: "虎族",
      800: "虎族",
      900: "虎族",
      1000: "伙伴"
    }
    platformShow = true;
    AppName = APP[parseInt(cc_source_code)];
  }

  const APP = <span className="Ubtn2">{AppName}</span>;

  // Handle Methods
  const _handleLink = (_params, _type) => {
    let params = {
      type_drawer: _params,
    };
    if (!_type || platformShow) handleLink(params);
  };

  const _handleLinkAdd = (_params, _id) => {
    if (!_id) return;
    let params = {
      type_drawer: _params,
      addedServiceId: _id,
    };
    handleLink(params);
  };

  const _handleLinkUser = (_params, _name) => {
    let params = {
      type_drawer: _params,
    };
    if (!_name) return;
    handleLink(params);
  };

  const _handleRefresh = () => {
    window.location.reload();
  };

  // Base Render
  const BaseBox = (
    <div
      onClick={() => _handleLink("DrawerShowSource", "copy")}
      className="m-box-item"
    >
      <p>{APP}</p>
      <p>{platformShow ? cc_source_name : "-"}</p>
    </div>
  );

  ucMvasList = ucMvasList.map((item) => {
    ucMvasDtoList && ucMvasDtoList.forEach((sub) => {
      if (item.addServicesTypeName === sub.addServicesTypeName) {
        item = sub;
      }
    });
    return item;
  });

  const UcMvasBox = (
    <div className="m-box-item">
      {ucMvasList.map((item, index) => (
        <span
          key={item.id || index}
          onClick={() => _handleLinkAdd("DrawerShowPhone", item.id)}
          className={`Ubtn2 u-span ${!item.id && "disabled"}`}
        >
          {item.addServicesTypeName}
        </span>
      ))}
    </div>
  );

  const Refresh = (
    <div className="m-box-item">
      <div className="u-refresh">
        <Popover placement="top" content={popover.refresh}>
          <SyncOutlined spin={false} onClick={_handleRefresh} />
        </Popover>
      </div>
    </div>
  );

  const Dc = (
    <div className="m-detail">
      {BaseBox}
      <div
        className="m-box-item"
        onClick={() => _handleLink("DrawerShowStore")}
      >
        {/* <p>店铺总数 <i>{gather.shopNum}</i></p>
        <p>已激活 <i>{gather.shopActivationNum}</i></p> */}
        <p>店铺</p>
        <p>信息</p>
      </div>
      {Refresh}
    </div>
  );

  const Yestae = (
    <div className="m-detail">
      {BaseBox}
      {UcMvasBox}
      <div
        className="m-box-item"
        onClick={() => _handleLink("DrawerShowTicket")}
      >
        <p className="u-p1">茶票</p>
        <p>
          可用 <i>{dataTicketList.length || 0}</i>
        </p>
      </div>
      <div
        className="m-box-item"
        onClick={() => _handleLinkUser("DrawerShowCopy", gather.realName)}
      >
        <p className="u-p1">{gather.realNameStatusName || "-"}</p>
        <p>{gather.realName || "-"}</p>
      </div>
      {Refresh}
    </div>
  );

  return platform === 100 ? Dc : Yestae;
};

export default HomeUserDetail;
