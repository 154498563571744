import numeral from 'numeral';
import moment from 'moment';

/**
 * @description: 格式化 URL 字符串
 * @param {String} ?a=1&c=2
 * @return: '{a: 1, c: 2}'
 */
export let getQuerySearch = function (search, item) {
  let hash;
  let myJson = {};
  if (!search) return {};
  let hashes = search.slice(search.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    let flag = false;
    if (Array.isArray(item)) {
      flag = item.includes(hash[0]);
    }
    else {
      flag = hash[0] === item ? true : false;
    }
    myJson[hash[0]] = flag ? hash[1] : decodeURIComponent(hash[1]);
  }
  return myJson;
}

export let getToParamString = function (search, item) {
  if (!search) {
    return ''
  }
  let paramList = []
  Object.keys(search) && Object.keys(search).forEach(key => {
    let val = search[key] || "";
    let flag = false;
    if (Array.isArray(item)) {
      flag = item.includes(key);
    } else {
      flag = key === item ? true : false;
    }
    if (!flag) {
      if (val && val.constructor === Array) {
        val.forEach(_val => {
          paramList.push(key + '=' + _val)
        })
      } else {
        paramList.push(key + '=' + val)
      }
    }
  })
  return paramList.join('&')
}

/**
 * @description: 格式化数字
 * @param {Number} num 数字
 * @return: '1,000'
 */
export const formatNumber = (num, decimal = 2) => {
  if (!num) return '0.00';
  let result = parseFloat(num).toFixed(decimal).toString().split('.');
  if (result.length > 1) {
    return `${numeral(result[0]).format('0,0')}.${result[1]}`;
  } else {
    return `${numeral(result[0]).format('0,0')}`;
  }
}

export function formatDatetime(date, formatter = 'YYYY-MM-DD HH:mm:ss') {
  if (!date || date === 'undefined' || date === 'null' || date === '0') {
    return '-'
  }
  if (typeof date === 'string') {
    let oldDate = date
    date = Number(oldDate)
    if (isNaN(date)) {
      date = new Date(oldDate)
    }
  }
  return moment(date).format(formatter)
}

export function formatDate(date, formatter = 'YYYY-MM-DD') {
  return formatDatetime(date, formatter)
}
