import { Button } from 'antd';
import { formatNumber } from '@/utils';

import { SendCard } from "@/utils/platform";
import { CARDTYPE } from '@/constant';

const DrawerSubRemark = (props) => {

  const { gather = {} } = props;
  // const dataGather = gather._item || {};
  const orderDetailDtoList = gather._item && gather._item.orderDetailDtoList;

  const BoxItem = orderDetailDtoList.map(item => (
    <div className="m-box">
      <div className="u-img">
        <img src={item.goodsUrl} alt="IMG" />
      </div>
      <div>
        <p className="u-p1">
          {item.goodsName || '-'} {item.goodsSpec || '-'}
        </p>
        <p className="u-p2">
          共{item.goodsQty || '-'}个商品，合计 ¥{formatNumber(item.goodsPayment)}
        </p>
      </div>
    </div>
  ));

  const handleSend = () => {
    console.log('handleSend ==>', gather);
    SendCard(gather, CARDTYPE.AFTERSALE);
    props.onClose();
  }

  return (
    <div className="drawer-yc-wrapper">
      <div className="m-drawer-top">
        <p className="u-p1">发起退换:</p>
      </div>
      <div className="return-goods">
        <div className="u-title">向买家发送退换货链接，方便买家快速进入售后页面</div>
        <div className="m-box-list">
          { BoxItem }
        </div>
      </div>
      <div className="m-drawer-sub">
        <Button
          onClick={handleSend}
          type="primary">发送
        </Button>
      </div>
    </div>
  )
};

export default DrawerSubRemark;
