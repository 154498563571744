import { Button } from 'antd';
import Data from './template';
import { formatDatetime, formatNumber } from '@/utils';

import { SendCard } from "@/utils/platform";
import { CARDTYPE } from '@/constant';

const DrawerShowInvoice = (props) => {

  const { gather = {} } = props;
  const dataList = Data[gather.type_drawer].list;
  const title = Data[gather.type_drawer].name;
  const dataGather = gather._item || {};

  const BoxItem = dataList.map((item, index) => (
    <p key={index}>
      <span className="u-s1">{item.name}:</span>
      <span>{item.special === 'money' ? formatNumber(dataGather[item.key]) : item.special === 'time' ? formatDatetime(dataGather[item.key]) : dataGather[item.key] || '-'}</span>
    </p>
  ));

  const handleSend = () => {
    console.log('handleSend ==>', dataGather);
    SendCard(gather, CARDTYPE.INVOICE);
    props.onClose();
  }

  return (
    <div className="drawer-yc-wrapper">
      <div className="m-drawer-top">
        <p className="u-p1">{title}：</p>
      </div>
      <div className="drawer-copy">
        {BoxItem}
      </div>
      <div className="m-drawer-sub">
        <Button
          onClick={handleSend}
          type="primary">发送
        </Button>
      </div>
    </div>
  )

};

export default DrawerShowInvoice;
