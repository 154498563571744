import React from 'react';
import { Form, Input, Button, message } from 'antd';

import { getList } from '@/api/base';
import { updateReciveAddress } from '@/api/order';

import BaseCascader from './BaseCascader';
import HomeLoading from '@c/HomeLoading';

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 18,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 21,
  },
};

const rulesItem = {
  required: true,
  message: '不能为空',
}

const getListAds = (productTypeName) => getList(productTypeName).then(function (res) {
  return res;
}).catch(function (error) {
  console.log(error);
})

export default class DrawerShowAddress extends React.Component {
  constructor(props) {
    super(props);
    let { gather = {}, visible } = this.props;
    let { platform, memberId, _item = {} } = gather;
    let { orderDetailDtoList = [], id, orderCode } = _item;
    let { receiveMobile, receiveName, address, provinceCode, cityCode, areaCode, areaName, cityName, provinceName } = orderDetailDtoList[0];
    let dataGather = {
      receiveMobile,
      receiveName,
      address,
      areaList: [provinceCode, cityCode, areaCode]
    }
    // console.log('DrawerShowAddress ==>', dataGather, gather);
    this.state = {
      dataListAddress: [],
      visible,
      platform,
      gather,
      dataGather,
      pageLoading: false,
      btnLoading: false,
      provinceCode,
      cityCode,
      memberId,
      orderId: id,
      orderCode,
      provinceName,
      cityName,
      areaName,
      isChange: false
    }
  }

  onFinish = async (values) => {
    let { areaList = [] } = values;
    let [provinceCode, cityCode, areaCode] = areaList;
    let { memberId, orderId, orderCode, platform, provinceName, cityName, areaName, gather } = this.state;
    let { _refresh, _index } = gather;
    let params = {
      ...values,
      provinceCode,
      cityCode,
      areaCode,
      memberId,
      orderId,
      orderCode,
      platform,
      provinceName,
      cityName,
      areaName
    }
    this.setState({ btnLoading: true })
    console.log('Send ==>', params);
    try {
      await updateReciveAddress(params);
      message.success('修改成功');
      _refresh({ _updata: params, _index });
    } catch (error) {}
    this.props.onClose();
    this.setState({ btnLoading: false })
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  handleChange = (props, list) => {
    console.log('handleChange ==>', props, list);
    let [provinceName, cityName, areaName] = list.map(item => item.label);

    console.log('productDisplayRender ==>', provinceName, cityName, areaName);
    this.setState({
      provinceName,
      cityName,
      areaName
    })
  }

  getDataList = async (_params = {}, _flag) => {
    let { pid = 0 } = _params;
    let params = {
      pid,
      platform: 100
    }
    let result = [];
    try {
      result = await getList(params);
      result && result.forEach(item => {
        item ? item.isLeaf = false : item.isLeaf = true;
      });
      if (!_flag) {
        this.setState({
          dataListAddress: [...result]
        })
      } else {
        return result;
      }

    } catch (error) { }
  }

  getLoadData = (selectedOptions) => {
    const len = selectedOptions.length
    const targetOption = selectedOptions[len - 1];
    targetOption.loading = true;
    console.log('targetOption ==>', targetOption, len);
    let params = { pid: targetOption.value, platform: 100 };

    getListAds(params).then(res => {
      targetOption.loading = false;
      targetOption.children = res && res.map(item => item.cityCodeId ?
        {
          value: item.cityCodeId,
          label: item.name,
          isLeaf: len === 2 ? true : false,
        } : "");

      this.setState({
        dataListAddress: [...this.state.dataListAddress]
      })
    });
  }

  componentDidMount() {
    let { platform, visible, provinceCode,
      cityCode } = this.state;
    let params = {
      pid: 0,
      platform
    };
    let TempArea = ['0', provinceCode, cityCode];

    // Edit
    if (TempArea.length > 0) {
      this.setState({
        pageLoading: true
      });

      visible && Promise.all(TempArea.map(async (pid, index) => {
        params.pid = pid;
        let res = await getListAds(params);
        return res && res.map(item => ({
          value: item.cityCodeId,
          label: item.name,
          isLeaf: index === 2 ? true : false,
        }))

      })).then(res => {
        let result = [];
        let [provinceList = []] = res;
        result = [...provinceList];
        TempArea.map((pid, index) => {
          if (index === 1) {
            result.forEach(item => {
              let TEMP = [];
              if (item.value === pid) {
                TEMP = res[index];
                item.children = TEMP;
              }
            });
          }
          else if (index === 2) {
            result.forEach(item => {
              item['children'] && item['children'].forEach(grd => {
                let TEMP = [];
                if (grd.value === pid) {
                  TEMP = res[index];
                  grd.children = TEMP;
                }
              });
            });
          }
          return result;
        });
        this.setState({
          dataListAddress: result,
          pageLoading: false
        });
      });
    } else {
      visible && getListAds(params).then(res => {
        const dataListAddress = res && res.map(item => item.cityCodeId ? {
          value: item.cityCodeId,
          label: item.name,
          isLeaf: false,
        } : "");
        console.log('getListAds ==>', dataListAddress);
        this.setState({
          dataListAddress
        });
      });
    }
  }

  productDisplayRender = (label) => {
    return label.join('/');
  };

  render() {
    let { dataListAddress, dataGather, pageLoading, btnLoading } = this.state;
    return (
      pageLoading ? <HomeLoading /> : <div className="drawer-yc-wrapper">
        <Form
          {...layout}
          name="basic"
          ref="form"
          initialValues={dataGather}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <Form.Item
            label="收件人"
            name="receiveName"
            rules={[rulesItem]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="地址"
            name="areaList"
            rules={[rulesItem]}
          >
            <BaseCascader
              initialValues={dataGather.areaList}
              getLoadData={this.getLoadData}
              dataListAddress={dataListAddress}
              onChange={this.handleChange}
              productDisplayRender={this.productDisplayRender}
            />
          </Form.Item>

          <Form.Item
            label="街道"
            name="address"
            rules={[rulesItem]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="电话"
            name="receiveMobile"
            rules={[rulesItem]}
          >
            <Input maxLength="11" />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <div className="m-cus-btn">
              <Button loading={btnLoading} type="primary" htmlType="submit">
                确认修改
              </Button>&emsp;
              <Button onClick={this.props.onClose}>
                关闭
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  };
}
