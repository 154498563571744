import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const HomeLoading = (props) => {
  let { size = "large", circle } = props;
  return (
    <div className="loading-wrapper">
      {
        circle ?
          <Spin size={size} indicator={antIcon} /> :
          <Spin size={size} />
      }
    </div>
  )
}

export default HomeLoading;
