import React from "react";
// import { getMemberShopDetail } from "@/api/home";
// import { Pagination } from "antd";
import { getMemberShopDetailByShopId } from "@/api/home";

import HomeLoading from "@c/HomeLoading";
import HomeNull from "@c/HomeNull";

export default class DrawerShowStore extends React.Component {
  constructor(props) {
    super(props);
    let { gather, visible } = this.props;
    this.state = {
      dataLists: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: null,
      gather,
      visible,
      pageLoading: true,
    };
  }

  async getList(_params = {}) {
    this.setState({
      pageLoading: true,
    });
    let {
      pageIndex,
      pageSize,
      gather: { shopId, platform },
    } = this.state;
    let params = {
      shopId,
      platform,
      pageIndex: _params.pageIndex || pageIndex,
      pageSize: _params.pageSize || pageSize,
    };
    try {
      // let { result, totalCount } = await getMemberShopDetail(params);
      let result = await getMemberShopDetailByShopId(params) || [];
      this.setState({
        dataLists: result || [],
        totalCount: result.length,
        pageIndex: params.pageIndex,
        pageSize: params.pageSize
      });
    } catch (error) {}
    this.setState({
      pageLoading: false,
    });
  }

  handlePageChange = (pageIndex, pageSize) => {
    let params = {
      pageIndex,
      pageSize,
    };
    this.getList(params);
  };

  componentDidMount() {
    let { visible } = this.state;
    visible && this.getList();
  }

  render() {
    // let { gather } = this.props;
    let { dataLists, pageLoading } =
      this.state;

    let BoxItem = dataLists.map((item) => {
      return (
        <div className="m-store-box" key={item.id}>
          <div className="m-store-title">
            <span>{item.shopName}</span>
            {/* <span className={!item.activateState ? 'u-error' : 'u-success'}>{item.activateStateName}</span> */}
          </div>
          <div className="m-store-detail">
            <p>
              <span className="u-s1">授权码:</span>
              <span>{item.shopAuthCode}</span>
            </p>
            <p>
              <span className="u-s1">店面级别:</span>
              <span>{item.shopLevelName}</span>
            </p>
            <p>
              <span className="u-s1">店面地址:</span>
              <span>
                {item.province}
                {item.city}
                {item.area}
              </span>
            </p>
            <p>
              <span className="u-s1">负责人姓名:</span>
              <span>{item.directorName}</span>
            </p>
            <p>
              <span className="u-s1">负责人电话:</span>
              <span>{item.directorMobile}</span>
            </p>
            <div className="m-state-fixed">
              <span className={!item.isUse ? "Ubtn" : "Ugbtn"}>
                {item.isUseName}
              </span>
            </div>
          </div>
        </div>
      );
    });
    let BoxMain = BoxItem.length ? BoxItem : <HomeNull />;

    return (
      <div className="drawer-yc-wrapper">
        <div className="m-drawer-top">
          <p className="u-p1">店铺详情:</p>
          {/* <p className="u-p2">共有 <i>{gather.shopNum}</i> 家门店，激活了 <i>{gather.shopActivationNum}</i> 家</p> */}
        </div>
        <div className="drawer-store">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>
        {/* 关闭分页 */}
        {/* <div className="m-drawer-footer">
          {totalCount > pageSize && (
            <Pagination
              size="small"
              total={totalCount}
              defaultPageSize={pageSize}
              showSizeChanger={false}
              current={pageIndex}
              showQuickJumper
              onChange={this.handlePageChange}
            />
          )}
        </div> */}
      </div>
    );
  }
}
