import React, { useRef, useState } from 'react';
import { Button, Drawer, message } from 'antd';
// import zhCN from 'antd/lib/locale/zh_CN';
import copy from "copy-to-clipboard";

import { getAllQuery } from '@/utils/storage';
import { updateOrderRemark } from '@/api/order';

import DrawerShowList from './DrawerShowList';
import DrawerShowRolls from './DrawerShowRolls';
import DrawerShowBase from './DrawerShowBase';
import DrawerShowTicket from './DrawerShowTicket';
import DrawerShowStore from './DrawerShowStore';
import DrawerShowCopy from './DrawerShowCopy';
import DrawerSubRemark from './DrawerSubRemark';
import DrawerShowInvoice from './DrawerShowInvoice';
import DrawerShowAddress from './DrawerShowAddress';
import DrawerShowLogistics from './DrawerShowLogistics';
import DrawerShowReturn from './DrawerShowReturn';

const CopyList = ['DrawerShowSource', 'DrawerShowCopy'];
const LowList = ['DrawerShowSource', 'DrawerShowCopy', 'DrawerSubRemark', 'DrawerShowInvoice', 'DrawerShowReturn'];
const RecordList = ['DrawerShowBase', 'DrawerShowPhone'];
const IsCopyList = ['DrawerShowPhone', 'DrawerShowSource', 'DrawerShowBase'];
const NoShowClode = ['DrawerShowAddress']

const HomeDrawer = (props) => {
  // Init Data
  let { visible, close, gather } = props;
  let { type_drawer } = gather;

  let [btnLoading, setLoading] = useState(false);

  const DrawerShowCopyRef = useRef(null);
  const DrawerSubRemarkRef = useRef(null);

  // Handle Methods
  const handleCopy = (e) => {
    const { copyVale } = DrawerShowCopyRef.current.state;
    console.log('handleCopy ==>', copyVale)
    if (copy(copyVale)) {
      message.success('复制完成');
    } else {
      message.error('Error');
    }
  }

  const handleSub = async () => {
    // btnLoading = true;
    setLoading(true);
    const { props } = DrawerSubRemarkRef.current.resizableTextArea;
    let { platform, memberId, _id, _orderCode, _refresh, _index, _subIndex } = gather;
    let { StaffAccount: staffAccount } = getAllQuery();

    gather._sellerRemark = props.value;

    let params = {
      orderCode: _orderCode,
      orderId: _id,
      platform,
      memberId,
      staffAccount,
      remark: props.value
    };

    if (!props.value) {
      setLoading(false);
      return message.error('客服备注不能为空');
    }

    try {
      await updateOrderRemark(params);
      message.success('提交成功');
      _refresh({ _sellerRemark: props.value, _index, _subIndex });
    } catch (error) { }
    close();
    setLoading(false);
  }

  const handleData = (e) => {
    console.log(e);
  }

  return (
    <Drawer
      placement="bottom"
      height={LowList.includes(type_drawer) ? "350px" : "85%"}
      closable={false}
      onClose={close}
      visible={visible}
      getContainer={false}
      style={{ position: 'absolute' }}
      drawerStyle={{ background: '#f2f2f2' }}
    >

      <div className="drawer-main" style={{ height: LowList.includes(type_drawer) ? "230px" : "92%" }}>
        {RecordList.includes(type_drawer) &&
          <DrawerShowBase
            key={parseInt(Math.random() * 100)}
            ref={DrawerShowCopyRef}
            visible={visible}
            gather={gather}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowRolls' &&
          <DrawerShowRolls
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowList' &&
          <DrawerShowList
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowTicket' &&
          <DrawerShowTicket
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            handleData={handleData} />}
        {type_drawer === 'DrawerShowStore' &&
          <DrawerShowStore
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            handleData={handleData} />
        }
        {CopyList.includes(type_drawer) &&
          <DrawerShowCopy
            ref={DrawerShowCopyRef}
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            drawer={type_drawer}
            handleData={handleData}
          />}
        {type_drawer === 'DrawerSubRemark' &&
          <DrawerSubRemark
            ref={DrawerSubRemarkRef}
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowInvoice' &&
          <DrawerShowInvoice
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            onClose={close}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowAddress' &&
          <DrawerShowAddress
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            onClose={close}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowLogistics' &&
          <DrawerShowLogistics
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            onClose={close}
            handleData={handleData} />
        }
        {type_drawer === 'DrawerShowReturn' &&
          <DrawerShowReturn
            key={parseInt(Math.random() * 100)}
            visible={visible}
            gather={gather}
            onClose={close}
            handleData={handleData} />
        }
      </div>
      <div className="drawer-wrapper">
        {IsCopyList.includes(type_drawer) &&
          <Button
            onClick={handleCopy}
            type="primary">复制
          </Button>}
        {type_drawer === 'DrawerSubRemark' &&
          <Button
            loading={btnLoading}
            onClick={handleSub}
            type="primary">提交
          </Button>}  &emsp;
        {!NoShowClode.includes(type_drawer) && <Button onClick={close}>关闭</Button>}
      </div>
    </Drawer>
  )
};

export default HomeDrawer;
