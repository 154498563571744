import React, { useRef } from 'react';
import DrawerShowCopy from './DrawerShowCopy';
import copy from "copy-to-clipboard";
import { message, Button } from 'antd';

const HomeVistors = (props) => {
  const DrawerShowCopyRef = useRef(null);
  const text = "当前用户为：访客（未注册会员）";

  // Handle Methods
  const handleCopy = (e) => {
    const { copyVale } = DrawerShowCopyRef.current.state;
    console.log('handleCopy ==>', copyVale)
    if (copy(copyVale)) {
      message.success('复制完成');
    } else {
      message.error('Error');
    }
  }
  return (
    <div className="visitors-inform">
      <p className="u-title"><span>{text}</span></p>
      <DrawerShowCopy ref={DrawerShowCopyRef} drawer='DrawerShowSource' />
      <Button style={{marginTop: '20px'}} size="small" onClick={handleCopy} type="primary">复制</Button>
    </div>
  )
}

export default HomeVistors;
