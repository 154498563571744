import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import RouterMap from './RouterMap';
import RouterAuth from './RouterAuth';
export default class IRouter extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" push />} />
          <RouterAuth routerConfig={RouterMap} />
        </Switch>
      </HashRouter>
    )
  }
}
