import React from 'react';
import loading from '@/assets/imgs/html_loading.png';

import { login } from "@/api/home";
import { getQuerySearch, getToParamString } from "@/utils";
import { setLoginInform } from "@/utils/storage";

export default class Login extends React.Component {
  getLogin = async () => {
    let { location, history } = this.props;
    let params = getQuerySearch(location.search);
    params.queryString = getToParamString(params, 'signature');
    let result  = await login(params);
    result && setLoginInform({ ...result, customQuery: params});
    history.push('/');
  }

  async componentDidMount() {
    await this.getLogin();
  }
  render() {
    return (
      <div className="loading_screen">
        <img className="loading_icon" src={loading} alt="Loading" />
      </div>
    )
  }
}
