import NoMatch from '@/layout/NoMatch';
import Login from '@/layout/Login';
import Home from '@/views/home';
import Goods from '@/views/goods';

let publicRoutes = [
  {
    path: "/home", name: 'Home', component: Home, exact: true,
  },
  {
    path: "/goods", name: 'Gome', component: Goods, exact: true,
  },
  {
    path: "/login", name: 'Login', component: Login, exact: true,
  },
  {
    path: "/404", name: '404', component: NoMatch, auth: true, exact: true,
  }
]
export default publicRoutes;
