import React from 'react';
import { Timeline, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import HomeLoading from '@c/HomeLoading';
import HomeNull from '@c/HomeNull';

import { SendCard } from "@/utils/platform";
import { CARDTYPE } from '@/constant';

import { getPackageByOrderId, getOrderLogistics } from '@/api/order';

// mock
// import { logisticsList } from "../mock/logistics";

export default class DrawerShowLogistics extends React.Component {

  constructor(props) {
    super(props);
    let { gather, visible, } = this.props;
    this.state = {
      gather,
      visible,
      pageLoading: true,
      dataGather: gather._item,
      dataLists: [],
      dataDetailLists: [],
      waybillNo: null,
      isShowDetail: false
    }
  }

  async getList(_params = {}) {
    this.setState({
      pageLoading: true
    });
    let { gather: { memberId, platform }, dataGather } = this.state;
    let { orderCode, id } = dataGather;
    let params = {
      memberId,
      platform,
      orderCode,
      orderId: id
    };
    try {
      let result = await getPackageByOrderId(params);
      // let { result } = logisticsList;
      let isShowDetail = result && result.length === 1;
      this.setState({
        dataLists: result || [],
        pageLoading: false,
        isShowDetail
      });
      if (isShowDetail) {
        await this.getListDetail({ ...params, waybillNo: result[0].waybillNo });
      }
    } catch (error) {
      this.setState({
        pageLoading: false
      })
    }

  }

  async getListDetail(_params = {}) {
    this.setState({
      pageLoading: true
    });
    let { gather: { memberId, platform } } = this.state;
    let { orderCode, orderId, waybillNo } = _params;
    let params = {
      memberId,
      platform,
      orderCode,
      orderId,
      waybillNo
    }
    try {
      let result = await getOrderLogistics(params);
      this.setState({
        dataDetailLists: result || [],
        pageLoading: false,
        waybillNo
      });
    } catch (error) { }
    this.setState({
      pageLoading: false
    })
  }

  handleOpenDetail = (_params) => {
    console.log('handleOpenDetail ==>', _params);
    this.setState({
      isShowDetail: true
    });
    this.getListDetail(_params);
  }

  hanldeBack = () => {
    this.setState({
      isShowDetail: false
    });
  }

  componentDidMount() {
    let { visible } = this.state;
    visible && this.getList();
  }

  handleSend = () => {
    let { gather } = this.state;
    console.log('handleSend ==>', gather);
    SendCard(gather, CARDTYPE.LOGISTICS);
    this.props.onClose();
  }

  render() {
    let { dataLists, pageLoading, dataDetailLists, waybillNo, isShowDetail } = this.state;

    let BoxItem = dataLists && dataLists.map((item, index) => {
      let { orderPackageGoodsDtoList = [] } = item;
      return (
        <div className="m-box" key={index}>
          <div className="m-title">包裹{index + 1}</div>
          {
            orderPackageGoodsDtoList.map((sub, subIndex) => {
              return (
                <div className="m-main" key={subIndex}>
                  <div className="u-img">
                    <img src={sub.goodsUrl} alt="IMG" />
                  </div>
                  <div>
                    <p className="u-p1">
                      {sub.goodsName} {sub.goodsSpec}
                    </p>
                    <p className="u-p2">
                      发货数量{sub.goodsQty || 0}个
                    </p>
                  </div>
                </div>
              )
            })
          }
          <div className="m-order">
            <div>
              <p>发货单号：{item.deliveryNo || '-'}</p>
              <p>快递单号：{item.waybillNo || '-'}</p>
            </div>
            <p onClick={() => this.handleOpenDetail(item)} className="u-h">详情 <RightOutlined /></p>
          </div>
        </div>
      )
    });

    let BoxLogistics = dataDetailLists && dataDetailLists.map((item, index) => (
      <Timeline.Item className="u-timeline" color={index === 0 ? 'green' : 'gray'} key={index}>
        <p>{item.datetime}</p>
        <p>{item.remark}</p>
      </Timeline.Item>
    ));

    let BoxMain = BoxItem && BoxItem.length ? BoxItem : <HomeNull />;
    let BoxMainLog = BoxLogistics && BoxLogistics.length ?
      <Timeline mode={'left'}>{BoxLogistics}</Timeline> :
      <HomeNull />;

    return (
      <div className="drawer-yc-wrapper">
        {!isShowDetail && <div className="m-drawer-top">
          <p className="u-p1">查看物流:</p>
        </div>}
        {!isShowDetail && <div className="parcel-detail">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>}
        {isShowDetail && <div className="logistics-detail animated fadeIn">
          {dataLists.length !== 1 && <p className="u-h" onClick={this.hanldeBack}><LeftOutlined /> 返回列表</p>}
          <p><span className="Ualink"> 快递单号: {waybillNo}</span></p>
          {pageLoading ? <HomeLoading /> : BoxMainLog}
        </div>}
        {isShowDetail && <div className="m-drawer-sub">
          <Button
            onClick={this.handleSend}
            type="primary">发送
          </Button>
        </div>}
      </div>
    )
  };
}
