import { createFromIconfontCN } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { message, Popover } from "antd";

import { MAP_PLATFORM } from "@/constant";
import { getCusQuery } from "@/utils/storage";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2492780_6gzgnsbvjz5.js",
});

const HomeUserIcon = (props) => {
  // Init Data
  let {
    handleLink,
    handleTab,
    type,
    name,
    gather = {},
    complete,
    label,
    labelClass,
  } = props;

  let platformType = gather.platform === 100;
  let curstom = (gather.otherPlatform && gather.otherPlatform[0]) || "";
  let iconTypeCard = gather.type === 1 ? "puka" : "jinka";
  let iconTypeSex = ["secert", "boy", "girl"][gather.sex];
  let iconLevel = {
    初阶茶道师: "初阶",
    二阶茶道师: "2阶",
    三阶茶道师: "3阶",
    四阶茶道师: "4阶",
  }[gather.teacherLevel];
  let dataRecordList = gather.dataRecordList || [];
  let popover = {
    copy: "复制",
  };

  // Source drill card - Can be switched cycle times
  let { cc_source_code } = getCusQuery();
  let PLATFORM = MAP_PLATFORM[parseInt(cc_source_code)] && MAP_PLATFORM[parseInt(cc_source_code)][0];

  // 来源钻卡 && 当前易购 && 没有钻卡信息
  if (!curstom && !platformType && PLATFORM === 100) {
    curstom = 100;
  }

  const handleCopy = (e) => {
    if (copy(name)) {
      message.success("复制完成");
    } else {
      message.error("Error");
    }
  };

  const _handleLink = () => {
    let params = {
      type_drawer: "DrawerShowBase",
    };
    handleLink(params);
  };

  const _handleTab = () => {
    handleTab(curstom);
  };

  let BoxYe = (
    <span className="Ubtn" onClick={() => _handleTab()}>
      益
    </span>
  );

  let BoxDc = (
    <IconFont
      onClick={() => _handleTab()}
      className="u-f24"
      type="iconhome_icon_zuanka_24"
    />
  );

  let BoxGather = {
    200: BoxYe,
    100: BoxDc,
  };

  const Dc = (
    <span>
      {/* &nbsp; */}
      {/* <span className="Ubtn2">{gather && gather.memberActivateStateName}</span> */}
      &nbsp;
      {BoxGather[curstom]}
    </span>
  );

  const Yestae = (
    <span>
      <IconFont
        className="u-f24"
        type={"iconhome_icon_" + iconTypeSex + "_24"}
      />
      <IconFont
        className="u-f24"
        type={"iconhome_icon_" + iconTypeCard + "_24"}
      />
      {iconLevel && <span className="Ubtn u-mr2">{iconLevel}</span>}
      {dataRecordList.length > 0 && (
        <span onClick={() => _handleLink()} className="Ubtn2 u-mr2">
          改
        </span>
      )}
      {BoxGather[curstom]}
    </span>
  );

  let CopyIcon = (
    <p className={label ? "m-remark" : ""}>
      {label && <span className={labelClass}>{label}</span>}
      <span className={`${complete ? "u-remark" : "u-name ellipsis"}`}>
        {name || "-"}
      </span>
      &nbsp;
      <Popover placement="top" content={popover.copy}>
        <IconFont
          type="iconhome_icon_copy_24"
          onClick={handleCopy}
          className="u-f18 u-copy"
        />
      </Popover>
    </p>
  );
  return type === "copy" ? CopyIcon : platformType ? Dc : Yestae;
};

export default HomeUserIcon;
