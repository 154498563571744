import React from "react";
import { Divider, ConfigProvider, Spin } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import {
  login,
  // getMemberInfrom,
  getMemberModifyPhoneLog,
  getTeaCouponList,
  getMemberInfoByMemberId,
} from "@/api/home";
import { getQuerySearch, getToParamString } from "@/utils";
import {
  setLoginInform,
  setLoginQuery,
  getLoginInform,
  getCusQuery,
} from "@/utils/storage";
import { MAP_PLATFORM } from "@/constant";

import HomeLoading from "@c/HomeLoading";
import HomeNull from "@c/HomeNull";

import HomeMoreList from "./components/HomeMoreList";
import HomeDrawer from "./components/HomeDrawer";
import HomeUserIcon from "./components/HomeUserIcon";
import HomeUserDetail from "./components/HomeUserDetail";
import HomeOrderList from "./components/HomeOrderList";
import HomeVisitors from "./components/HomeVisitors";

let MoreIcon = (props) => {
  return props.showMore ? (
    <span>
      收起更多数据
      <CaretUpOutlined />
    </span>
  ) : (
    <span>
      打开更多数据
      <CaretDownOutlined />
    </span>
  );
};

export default class App extends React.Component {
  // Init Data
  state = {
    showMore: false,
    showVisible: false,
    tabIndex: 0,
    pageLoading: true,
    tabChangeIndex: 100,

    dataMemberDto: [],
    dataYestaeMemberDto: {},
    dataGMemberDto: {},

    dataTypeDrawer: {},
    isHasPhone: true,
    queryName: null,

    // Init record && ticket
    dataRecordList: [],
    dataTicketList: [],

    // To optimize the
    dataParams: {},
    headLoadign: false,
  };

  // Handle Methods
  handleToggleMore = (index) => {
    let dataMemberDto = this.state.dataMemberDto;
    dataMemberDto[index].showMore = !dataMemberDto[index].showMore;
    this.setState({
      dataMemberDto,
    });
  };
  handleDrawerOpen = (params) => {
    this.setState({
      showVisible: true,
      dataTypeDrawer: params,
    });
  };
  handleDrawerClose = () => {
    this.setState({
      showVisible: false,
    });
  };
  handleTab = async (_type) => {
    let { dataParams } = this.state;
    this.setLoading(true, "headLoadign");

    let tabIndex = this.state.tabIndex === 1 ? 0 : 1;
    this.setState({
      tabIndex,
      tabChangeIndex: tabIndex,
    });

    console.log("dataParams ==>", dataParams, _type);

    await this.getUserInform({
      ...dataParams,
      _platform: _type,
      _changeDc: _type,
    });
    this.setLoading(false, "headLoadign");
  };
  handleClearQueryName = () => {
    console.log("handleClearQueryName ==>");
    this.setState({
      queryName: "",
    });
  };

  // Get Data
  getLogin = async (params) => {
    let loginInform = getLoginInform() || {};
    if (loginInform.token) return;
    let result = await login(params);
    result && setLoginInform({ ...result, customQuery: params });
  };

  getUserInform = async (params) => {
    let { MobilePhone, UserId, _platform, _changeDc } = params;
    let { userId } = (await getLoginInform()) || {};
    let { cc_source_code, bmp_user_id } = getCusQuery();
    console.log("getCusQuery ==>", getCusQuery(), _changeDc, UserId);
    let { dataGMemberDto, dataMemberDto } = this.state;

    let platform =
      _platform ||
      (cc_source_code &&
        MAP_PLATFORM[parseInt(cc_source_code)] &&
        MAP_PLATFORM[parseInt(cc_source_code)][0]);

    // 自定义拦截
    platform = platform === 400 ? 200 : platform;

    if (dataGMemberDto[platform]) return;
    let newparams = {
      memberId: bmp_user_id || UserId,
      platform,
      memberMobile: MobilePhone,
      ccUserId: userId,
    };
    if (_changeDc === 100) {
      newparams.memberId = "";
    }

    console.log("newparams ==>", newparams, _changeDc);

    try {
      let result = await getMemberInfoByMemberId({ ...newparams });

      let doc = { ...result, showMore: false };

      dataMemberDto.push(doc);
      dataMemberDto = dataMemberDto.filter((item) => item.memberId);

      dataGMemberDto[platform] = doc;

      this.setState(
        {
          dataMemberDto,
          dataGMemberDto,
        },
        () => {
          if (platform === 200) {
            this.getYeateList(platform);
          }
        }
      );
    } catch (error) {}
  };

  getMemberModifyPhone = async () => {
    let { dataGMemberDto } = this.state;
    let { memberId, platform } = dataGMemberDto[200];
    if (!memberId) return;
    let { result } = await getMemberModifyPhoneLog({ memberId, platform });
    this.setState({
      dataRecordList: result,
    });
  };

  getTeaCouponList = async () => {
    let { dataGMemberDto } = this.state;
    let { memberId, platform } = dataGMemberDto[200];
    if (!memberId) return;
    let result = await getTeaCouponList({ memberId, platform });
    let dataTicketList =
      result.filter((item) => [100, 200].includes(item.status)) || [];

    this.setState({
      dataTicketList,
    });
  };

  setLoading = (_loading, key = "pageLoading") => {
    this.setState({
      [key]: _loading,
    });
  };

  getYeateList = async (platform) => {
    if (platform !== 200) return;
    await this.getMemberModifyPhone();
    await this.getTeaCouponList();
  };

  async componentDidMount() {
    this.setLoading(true);

    let that = this;

    window.addEventListener("message", function (e) {
      let { data, origin } = e;
      if (data && origin.indexOf("qiyukf") !== -1) {
        data = typeof data === "string" ? JSON.parse(data) : data;
        let id = typeof data === "object" ? data.id : data;
        console.log("origin ==> 2", origin, data, id);
        that.setState({
          queryName: id,
          tabIndex: 0,
        });
      }
    });

    let { location } = this.props;

    // Filter transcode parameters
    let params = getQuerySearch(location.search, ["qiyuCustomer"]);
    if (!Object.keys(params).length) {
      return this.setState({
        isHasPhone: false,
        pageLoading: false,
      });
    }

    // Filter blending parameters
    params.queryString = getToParamString(params, ["signature", "appId"]);

    // Cache data
    setLoginQuery({ customQuery: params });

    if (!params.MobilePhone || params.MobilePhone === "-") {
      return this.setState({
        isHasPhone: false,
        pageLoading: false,
      });
    }

    // console.log("componentDidMount ==>", params);
    // Storing basic Information
    this.setState({
      dataParams: params,
    });

    let { cc_source_code } = getCusQuery();
    let platform =
      MAP_PLATFORM[parseInt(cc_source_code)] &&
      MAP_PLATFORM[parseInt(cc_source_code)][0];

    console.log("params ==>", params);

    try {
      await this.getLogin(params);
      await this.getUserInform(params);
      await this.getYeateList(platform);
    } catch (error) {
      console.log("error ==>", error);
    }

    this.setLoading(false);
  }

  render() {
    let {
      showVisible,
      tabIndex,
      pageLoading,
      dataMemberDto,
      dataTypeDrawer,
      isHasPhone,
      dataRecordList,
      dataTicketList,
      queryName,
      tabChangeIndex,
      headLoadign,
    } = this.state;

    let DrawerData =
      (dataMemberDto.length === 1
        ? dataMemberDto[0]
        : dataMemberDto[tabIndex]) || {};

    let TabClassChange = (index, _len) => {
      if (_len === 1) {
        return "fadeIn";
      }
      if (tabChangeIndex === 100) {
        if (index === 0) {
          return tabIndex === 0 ? "fadeIn" : "";
        } else if (index === 1) {
          return tabIndex === 1 ? "" : "hide";
        }
      } else {
        if (index === 0) {
          return tabIndex === 0 ? "slideInRight" : "slideOutRight";
        } else if (index === 1) {
          return tabIndex === 1 ? "slideInLeft" : "slideOutLeft";
        }
      }
    };

    let BoxItem = dataMemberDto.map((item, index) => {
      let showMore = dataMemberDto[index].showMore;
      return (
        <div
          key={index}
          className={`platform-box animated ${TabClassChange(
            index,
            dataMemberDto.length
          )}`}
        >
          {/* UserInfrom */}
          <Spin spinning={headLoadign}>
            <div
              className={`user-top-inform ${
                item.platform === 200
                  ? "media-yestae-inform"
                  : "media-dc-inform"
              }`}
            >
              <div className="m-user-box">
                <HomeUserIcon name={item.memberName} type="copy" />
                <div className="m-phone">
                  <HomeUserIcon
                    handleLink={this.handleDrawerOpen}
                    name={item.memberMobile}
                    type="copy"
                  />
                  <HomeUserIcon
                    handleLink={this.handleDrawerOpen}
                    handleTab={this.handleTab}
                    gather={Object.assign(item, { dataRecordList })}
                  />
                </div>
              </div>
              <HomeUserDetail
                gather={Object.assign(item, { dataTicketList })}
                handleLink={this.handleDrawerOpen}
              />
            </div>
          </Spin>
          {/* More */}
          <div className="m-check-more">
            <div className="u-text">
              <Divider>
                <span onClick={() => this.handleToggleMore(index)}>
                  <MoreIcon showMore={item.showMore} />
                </span>
              </Divider>
            </div>
            {/* The detailed information */}
            {showMore ? (
              <HomeMoreList gather={item} handleLink={this.handleDrawerOpen} />
            ) : (
              ""
            )}
          </div>
          {/* Order */}
          {
            <HomeOrderList
              index={index}
              cIndex={tabIndex}
              gather={item}
              queryName={queryName}
              handleClearQueryName={this.handleClearQueryName}
              handleLink={this.handleDrawerOpen}
            />
          }
        </div>
      );
    });

    let BoxMain =
      BoxItem.length > 0 ? BoxItem : <HomeNull title="该用户暂无数据" />;

    return pageLoading ? (
      <HomeLoading />
    ) : (
      <ConfigProvider locale={zhCN}>
        <div className="home-wrapper">
          {isHasPhone ? BoxMain : <HomeVisitors />}
          {/* 抽屉信息 */}
          <HomeDrawer
            visible={showVisible}
            close={this.handleDrawerClose}
            gather={Object.assign(DrawerData, dataTypeDrawer)}
          />
        </div>
      </ConfigProvider>
    );
  }
}
