import React from "react";
import {
  Input,
  Select,
  Button,
  Pagination,
  ConfigProvider,
  message,
  Modal,
} from "antd";
import zhCN from "antd/lib/locale/zh_CN";

import HomeLoading from "@c/HomeLoading";
import HomeNull from "@c/HomeNull";

import HomeVisitors from "../home/components/HomeVisitors";

import { getPage } from "@/api/goods";
import { getMemberInfrom } from "@/api/home";
import { formatNumber } from "@/utils";

import { getCusQuery, getAllQuery } from "@/utils/storage";

import {
  SendCard,
  SendPlatform,
  DealGoodsPrice,
  DealPcGoodsPrice,
} from "@/utils/platform";
import { CARDTYPE, MAP_PLATFORM, PC_CODE_LIST } from "@/constant";

const { Option } = Select;
const { Search } = Input;
const { confirm } = Modal;

export default class Goods extends React.Component {
  constructor(props) {
    super(props);

    let { cc_source_code } = getCusQuery() || {};
    let LIST = MAP_PLATFORM[parseInt(cc_source_code)];
    // console.log("Goods ===>", cc_source_code, LIST);

    this.state = {
      // page data
      queryName: null,
      memberId: null,
      pageIndex: 1,
      pageSize: 10,
      totalCount: null,
      dataLists: [],

      // handle data
      pageLoading: false,
      pageLoadingHead: false,
      iptValue: null,
      setValue: null,
      platform: cc_source_code ? LIST && LIST[0] : 200,
      platformOriginal: cc_source_code ? LIST && LIST[0] : "",
      platformList: [
        {
          name: "益购",
          key: 200,
        },
        {
          name: "钻卡",
          key: 100,
        },
        {
          name: "虎族",
          key: 300,
          disabled: true
        },
        {
          name: "益伙伴",
          key: 400,
          disabled: true
        },
      ],
      cc_source_code,
      isVisitor: false,

      // card loading
      loadBtnList: [],
    };
  }

  async getUserInform() {
    this.setState({
      pageLoadingHead: true,
    });
    let { MobilePhone } = getAllQuery();
    if (!MobilePhone || MobilePhone === "-")
      return this.setState({
        pageLoadingHead: false,
      });
    try {
      let { dcMemberDto = {}, yestaeMemberDto = {} } = await getMemberInfrom({
        memberMobile: MobilePhone,
      });
      this.setState({
        100: dcMemberDto,
        200: yestaeMemberDto,
        pageLoadingHead: false,
      });
    } catch (error) {
      this.setState({
        pageLoadingHead: false,
      });
    }
  }

  async getList(_params = {}) {
    this.setLoading(true);
    let { platform, queryName, pageIndex, pageSize, cc_source_code } =
      this.state;
    let dto = (platform && this.state[platform]) || {};
    let params = {
      queryName,
      platform,
      memberId: dto.memberId,
      pageIndex: _params.pageIndex || pageIndex,
      pageSize: _params.pageSize || pageSize,
    };
    let APPTYPE = !PC_CODE_LIST.includes(parseInt(cc_source_code));
    console.log("APPTYPE ==>", APPTYPE);
    try {
      let { result, totalCount } = await getPage(params);
      if (platform === 200) {
        result &&
          result.forEach((item) => {
            // PC 商品价格 && 移动端 商品价格
            if (parseInt(cc_source_code) === 600) {
              DealPcGoodsPrice(dto.type, item);
            } else {
              DealGoodsPrice(dto.type, item);
            }
            // 自定义价格 && 图片 字段
            item.goodsPriceCustom = `${
              item.intPrice ? formatNumber(item.intPrice, 0) : "0"
            }.${item.floatPrice ? item.floatPrice : "00"}`;

            // 受益卷商品价格
            parseInt(cc_source_code) !== 600 &&
              (item.goodsPriceCustom =
                item.coinState === 2
                  ? `${item.yestaeCurrencyCount} 受益券`
                  : item.coinState === 3
                  ? `${item.yestaeCurrencyCount}受益券${
                      item.goodsPriceCustom ? "+¥" + item.goodsPriceCustom : ""
                    }`
                  : item.goodsPriceCustom);

            item.goodsUrlCustom = APPTYPE ? item.goodsUrl : item.pcGoodsUrl;
          });
      } else {
        result &&
          result.forEach((item) => {
            // 自定义价格 && 图片 字段
            item.goodsPriceCustom = formatNumber(item.goodsPrice);
            item.goodsUrlCustom = APPTYPE ? item.goodsUrl : item.pcGoodsUrl;
          });
      }

      this.setState({
        dataLists: result || [],
        pageLoading: false,
        totalCount,
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        loadBtnList: result.map((item) => item.loading || false),
      });
    } catch (error) {
      this.setLoading(false);
    }
  }

  setLoading = (_loading) => {
    this.setState({
      pageLoading: _loading,
    });
  };

  handleChangeSelect = (val) => {
    this.setState({
      platform: val,
    });
  };

  handleChangeIpt = (_val) => {
    this.setState(
      {
        queryName: _val,
      },
      () => this.handleSearch()
    );
  };

  handlePageChange = (pageIndex, pageSize) => {
    let params = {
      pageIndex,
      pageSize,
    };
    this.getList(params);
  };

  handleSearch = () => {
    let { queryName } = this.state;
    if (!queryName) return message.error("请输入商品名称或商品编号");

    this.setState(
      {
        pageIndex: 1,
      },
      () => this.getList()
    );
  };

  handleSend = (_goods, _index) => {
    let that = this;
    let memberId =
      this.state[_goods.platform] && this.state[_goods.platform].memberId;
    let params = {
      _goods,
      memberId,
      platform: _goods.platform,
    };
    // console.log("handleSend ==>", params, _index);
    confirm({
      title: "卡片信息",
      icon: "",
      content: "确认发送商品？",
      onOk() {
        that.setBtnLoading(that, _index, true);
        SendCard(params, CARDTYPE.GOODS, () => {
          that.setBtnLoading(that, _index, false);
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  setBtnLoading(_that, _index, _val) {
    let { loadBtnList } = _that.state;
    loadBtnList[_index] = _val;
    _that.setState({
      loadBtnList,
    });
  }

  handleSendPlatform = (item) => {
    confirm({
      title: "业务平台",
      icon: "",
      content: "确认跳转？",
      onOk() {
        let params = {
          _goods: item,
          platform: item.platform,
        };
        SendPlatform(params, CARDTYPE.GOODS);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  async componentDidMount() {
    let that = this;

    window.addEventListener(
      "message",
      function (e) {
        let { origin, data } = e || {};
        if (data && origin.indexOf("qiyukf") !== -1) {
          that.setState({ pageLoadingHead: true });
          data = typeof data === "string" ? JSON.parse(data) : data;
          let id = typeof data === "object" ? data.id : data;
          if (!id) return that.setState({ pageLoadingHead: false });
          that.setState(
            {
              queryName: id,
              pageLoadingHead: false,
            },
            () => {
              that.handleSearch();
            }
          );
        } else {
          that.setState({ pageLoadingHead: false });
        }
      },
      false
    );

    that.setState({ pageLoadingHead: false });

    await this.getUserInform();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    let {
      dataLists,
      totalCount,
      pageSize,
      pageIndex,
      pageLoading,
      platform,
      platformList,
      queryName,
      pageLoadingHead,
      platformOriginal,
      loadBtnList,
      isVisitor,
    } = this.state;

    let BoxItem =
      dataLists &&
      dataLists.map((item, index) => {
        let { goodsPriceCustom = "" } = item;
        return (
          <div className="goods-box animated fadeIn" key={index}>
            <div className="u-img">
              <img src={item.goodsUrlCustom} alt="IMG" />
            </div>
            <div>
              <p>{item.goodsName}</p>
              <p>{item.goodsSpec}</p>
              <p>
                价格：
                <i>
                  {goodsPriceCustom && goodsPriceCustom.indexOf("¥") !== -1
                    ? ""
                    : "¥"}
                  {goodsPriceCustom}
                </i>
              </p>
              <p>编号：{item.goodsSku}</p>
              <p>库存：{item.stockNum}</p>
            </div>
            <div className="m-fixed">
              <Button
                loading={loadBtnList[index]}
                size="small"
                disabled={item.platform !== platformOriginal}
                onClick={() => this.handleSend(item, index)}
              >
                发送
              </Button>
              &nbsp;
              <Button
                size="small"
                onClick={() => this.handleSendPlatform(item)}
              >
                详情
              </Button>
            </div>
          </div>
        );
      });
    let BoxMain = BoxItem.length ? BoxItem : <HomeNull />;

    // 暂时关闭虎族/益伙伴来源搜索
    let BoxSearchUse = ![100, 200].includes(platform);

    return isVisitor ? (
      <HomeVisitors />
    ) : (
      <div className="goods-wrapper">
        {pageLoadingHead ? (
          <HomeLoading />
        ) : (
          <div className="goods-search">
            <Input.Group compact>
              <Select
                onChange={this.handleChangeSelect}
                placeholder="请选择"
                defaultValue={platform}
                style={{ width: "20%" }}
                disabled={BoxSearchUse}
              >
                {platformList.map((item, index) => (
                  <Option key={index} value={item.key} disabled={item.disabled}>
                    {item.name}
                  </Option>
                ))}
              </Select>

              <Search
                placeholder="请输入商品名称或商品编号"
                style={{ width: "80%" }}
                defaultValue={queryName}
                enterButton="搜索"
                onSearch={this.handleChangeIpt}
                disabled={BoxSearchUse}
              />
            </Input.Group>
          </div>
        )}
        <div className="goods-list">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>
        <div className="goods-footer">
          <ConfigProvider locale={zhCN}>
            <Pagination
              hideOnSinglePage={true}
              size="small"
              total={totalCount}
              defaultPageSize={pageSize}
              showSizeChanger={false}
              showQuickJumper
              current={pageIndex}
              onChange={this.handlePageChange}
            />
          </ConfigProvider>
        </div>
      </div>
    );
  }
}
