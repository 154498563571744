import BaseAuth from '@/config/network/BaseAuth';
import { member, open } from './service-base';

// 获取 Token
export function login(params) {
  return BaseAuth({
    url: `${open}/open/login`,
    method: 'POST',
    data: params
  });
}

// 获取会员信息
export function getMemberInfrom(params) {
  return BaseAuth({
    url: `${member}/member/getMemberInfo`,
    method: 'POST',
    data: params
  });
}

// 获取价格信息
export function getMemberDepositDetail(params) {
  return BaseAuth({
    url: `${member}/member/getMemberDepositDetail`,
    method: 'POST',
    data: params
  });
}

// 获取店铺信息
export function getMemberShopDetail(params) {
  return BaseAuth({
    url: `${member}/member/getMemberShopDetail`,
    method: 'POST',
    data: params
  });
}

// 茶票信息详情
export function getTeaCouponList(params) {
  return BaseAuth({
    url: `${member}/member/getTeaCouponList`,
    method: 'POST',
    data: params
  });
}

// 受益卷详情
export function getMemberCouponDetail(params) {
  return BaseAuth({
    url: `${member}/member/getMemberCouponDetail`,
    method: 'POST',
    data: params
  });
}

// 增值服务详情
export function getAddServicesDetailList(params) {
  return BaseAuth({
    url: `${member}/member/getAddServicesDetailList`,
    method: 'POST',
    data: params
  });
}

// 修改手机号
export function getMemberModifyPhoneLog(params) {
  return BaseAuth({
    url: `${member}/member/getMemberModifyPhoneLog`,
    method: 'POST',
    data: params
  });
}

// 获取用户信息【2021年08月30日11:18:48】
export function getMemberInfoByMemberId(params) {
  return BaseAuth({
    url: `${member}/member/getMemberInfoByMemberId`,
    method: 'POST',
    data: params
  });
}

// 获取店铺信息【2021年09月03日16:47:10】
export function getMemberShopDetailByShopId(params) {
  return BaseAuth({
    url: `${member}/member/getMemberShopDetailByShopId`,
    method: 'POST',
    data: params
  });
}
