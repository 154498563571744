/**
 * 基础接口配置
 * Export URL
 * https://cc.tae-tea.net
 * https://cc-test.yestae.com
 * https://cc.yestae.com
 * */

const local = {
  BaseAuth: 'https://gateway-bmp-test.yestae.com'
}

const dev = {
  BaseAuth: 'http://gateway-bmp.tae-tea.net'
}

const test = {
  BaseAuth: 'https://gateway-bmp-test.yestae.com'
}

const prod = {
  BaseAuth: 'https://gateway-bmp.yestae.com'
}

const APIGather = {
  dev,
  test,
  prod,
  local
 }

const { REACT_APP_MODE } = process.env;

const ENV = REACT_APP_MODE ? REACT_APP_MODE : 'local'

export default APIGather[ENV];
