import React from 'react';
import Data from './template';
import { getCusQuery } from '@/utils/storage';
export default class DrawerShowCopy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.drawer,
      title: Data[this.props.drawer].name,
      dataList: Data[this.props.drawer].list,
      dataGather: {},
      copyVale: null
    }
  }

  componentDidMount() {
    let dataQuery = getCusQuery();
    let { type, dataList } = this.state;
    let { gather } = this.props;
    let dataGather = type === 'DrawerShowSource' ? dataQuery : gather;
    let copyVale = "";
    dataList.map(item => {
      return copyVale += `${item.name}: ${dataGather[item.key] || '-'}\n`;
    })
    this.setState({
      dataGather,
      copyVale
    });
  }

  render() {
    let { title, dataList, dataGather } = this.state;

    let BoxItem = dataList.map((item, index) => (
      <p key={index}><span className="u-s1">{item.name}:</span><span>{dataGather[item.key] || '-'}</span></p>
    ));

    return (
      <div className="drawer-yc-wrapper">
        <div className="m-drawer-top">
          <p className="u-p1">{title}：</p>
        </div>
        <div className="drawer-copy">
          {BoxItem}
        </div>
      </div>
    )
  };
}
