import React from 'react';
import { createFromIconfontCN, CaretUpOutlined, CaretDownOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Pagination, Dropdown, Popover, Input, message, Modal } from 'antd';
import { getOrderPage } from '@/api/order';

import { HomeOrderTab, HomeOrderIcon, HomeOrderBtn, HomeOrderListIcon } from './template';

import { formatDatetime, formatNumber } from '@/utils';
import { getCusQuery } from '@/utils/storage';
import { SendCard, SendPlatform } from "@/utils/platform";
import { CARDTYPE, MAP_PLATFORM } from '@/constant';

import HomeLoading from '@c/HomeLoading';
import HomeNull from '@c/HomeNull';
import HomeUserIcon from './HomeUserIcon';

const { confirm } = Modal;

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2492780_6gzgnsbvjz5.js',
});

const OrderMenu = (props) => {
  let { dataList = [], handleVisibleChange } = props;
  let menu = (
    <div className="Dropdown_order-menu-box">
      {dataList && dataList.length > 0 ?
        dataList.map((item, index) => {
          return (
            <div key={index}>{item.couponTypeName}: ￥{formatNumber(item.couponAmount)}</div>
          )
        }) :
        <div>暂无优惠信息</div>}
    </div>);

  return (
    <Dropdown
      arrow="true"
      placement="bottomRight"
      onVisibleChange={handleVisibleChange}
      overlay={menu} trigger={['click']}>
      <p className="u-preferential" onClick={e => e.preventDefault()}>
        {/* 优惠 {arrow ? <span className="u-arrow"><UpOutlined /></span> : <span className="u-arrow"><DownOutlined /></span>} */}
        优惠 <DownOutlined />
      </p>
    </Dropdown>
  );
}

const ToggleArrow = (props) => {
  return props.type ?
    <span className="u-h">收起 <CaretUpOutlined /></span> :
    <span className="u-h">展开 <CaretDownOutlined /></span>;
}
class HomeOrderList extends React.Component {
  constructor(props) {
    super(props);
    let { gather, queryName, index, cIndex } = this.props;
    let { cc_source_code } = getCusQuery() || {};
    let LIST = MAP_PLATFORM[parseInt(cc_source_code)];

    let queryNameTemp = index === 0 ? queryName : '';
    console.log('Goods ===>', cc_source_code, LIST, index, cIndex);
    this.state = {
      pageBtnList: HomeOrderBtn,
      // tab
      tabList: HomeOrderTab,
      tabIcon: HomeOrderIcon,
      tabListIndex: 0,
      // icon
      dataLists: [],
      dataTabLists: {},
      totalCount: null,
      dataListsIcon: HomeOrderListIcon,
      // page params
      pageIndex: 1,
      pageSize: 10,
      orderStatus: queryNameTemp ? 100 : '',
      gather,
      // page handle
      pageLoading: true,
      dataListsToggle: [],
      // search
      isSearchFlag: queryNameTemp ? true : false,
      queryName: queryNameTemp,
      pageLoadingHead: false,
      platformOriginal: cc_source_code ? LIST && LIST[0] : ''
    }
  }

  async getList(_params = {}, _callback) {
    this.setLoading(true);
    let { pageIndex, pageSize, gather: { memberId, platform } = {}, orderStatus } = this.state;
    let params = {
      memberId,
      platform,
      pageIndex: _params.pageIndex || pageIndex,
      pageSize: _params.pageSize || pageSize,
      orderStatus: _params.orderStatus || orderStatus,
      orderCode: _params.orderCode
    };
    try {
      let { result, totalCount } = await getOrderPage(params);
      let dataLists = (result[0] && result[0].orderDtoList) || [];

      let dataTabLists = (result[0] && result[0].orderStatusStatisticsDto) || {};
      let dataListsToggle = dataLists.map((item, index) => ({
        toggle: item.toggle || index === 0 ? true : false,
        saleTop: false,
        saleBttom: false
      }));

      this.setState({
        dataLists,
        dataTabLists,
        totalCount,
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        pageLoading: false,
        dataListsToggle,
        orderStatus: params.orderStatus,
        isLoadData: true
      });
      _callback && _callback();
    } catch (e) {
      console.log(e)
      this.setLoading(false);
    }
  }

  updataList(_params) {
    let { _index, _subIndex = 0, _sellerRemark } = _params;
    let { dataLists } = this.state;
    dataLists[_index].orderDetailDtoList[_subIndex].sellerRemark = _sellerRemark;
    dataLists[_index].sellerRemarkFlag = 200;

    console.log('updataList ==>', _params, dataLists);
    this.setSingle('dataLists', dataLists);
  }

  updataListAddress(_params) {
    let { _updata, _index, _subIndex = 0 } = _params;
    let { address, areaCode, areaName, cityCode, cityName, provinceCode, provinceName, receiveMobile, receiveName } = _updata;
    let { dataLists } = this.state;

    console.log('updataListAddress ==>', address, areaCode, areaName, cityCode, cityName, provinceCode, provinceName, receiveMobile, receiveName, _index);

    dataLists[_index].orderDetailDtoList[_subIndex].address = address;
    dataLists[_index].orderDetailDtoList[_subIndex].areaCode = areaCode;
    dataLists[_index].orderDetailDtoList[_subIndex].areaName = areaName;
    dataLists[_index].orderDetailDtoList[_subIndex].cityCode = cityCode;
    dataLists[_index].orderDetailDtoList[_subIndex].cityName = cityName;
    dataLists[_index].orderDetailDtoList[_subIndex].provinceCode = provinceCode;
    dataLists[_index].orderDetailDtoList[_subIndex].provinceName = provinceName;
    dataLists[_index].orderDetailDtoList[_subIndex].receiveMobile = receiveMobile;
    dataLists[_index].orderDetailDtoList[_subIndex].receiveName = receiveName;

    this.setSingle('dataLists', dataLists);
  }

  handleToggleDetail = (_index) => {
    let { dataListsToggle } = this.state;
    dataListsToggle[_index].toggle = !dataListsToggle[_index].toggle;

    this.setState({
      dataListsToggle
    });
  }

  handleVisibleChange = (_type, _index) => {
    let { dataListsToggle } = this.state;
    dataListsToggle[_index][_type] = !dataListsToggle[_index][_type];

    this.setState({
      dataListsToggle
    });
  }

  handlePageChange = (pageIndex, pageSize) => {
    let params = {
      pageIndex,
      pageSize
    }
    this.getList(params);
  }

  handleTabChange(_params, _index) {
    let params = {
      orderStatus: _params.code,
      pageIndex: 1
    }
    this.setSingle('tabListIndex', _index);

    this._clearSearch();

    this.getList(params);
  }

  _clearSearch() {
    this.setState({
      isSearchFlag: false,
      queryName: ''
    });
    this.props.handleClearQueryName();
  }

  handleTabIconChange = (_params) => {
    console.log('handleTabIconChange ==>', _params);
    let that = this;
    switch (_params.key) {
      case 'shuaxin':
        this._clearSearch()
        this.getList({}, () => {
          message.success('刷新成功');
        });
        break;
      case 'sousuo':
        let { isSearchFlag } = that.state;
        this.setSingle('isSearchFlag', !isSearchFlag);
        break;
      case 'lishi':
        confirm({
          title: '业务平台',
          icon: '',
          content: `确认跳转${_params.name}？`,
          onOk() {
            let { gather } = that.state;
            let params = {
              _item: _params,
              platform: gather.platform
            }
            SendPlatform(params, CARDTYPE.ORDER_LIST);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        break;
      default:
        break;
    }

  }

  handleDataListsIconChange = (_params, _item) => {
    console.log('handleDataListsIconChange ==>', _params, _item);
    let { code, fullName } = _params;
    if ([10, 20].includes(code)) {
      let TYPE = code === 10 ? CARDTYPE.ORDER : CARDTYPE.INVOICE;

      confirm({
        title: '业务平台',
        icon: '',
        content: `确认跳转${fullName}？`,
        onOk() {
          let params = {
            _item,
            platform: _item.platform
          }
          SendPlatform(params, TYPE);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }

  handleAftersale = (_item) => {
    console.log('handleAftersale ==>', _item);
    confirm({
      title: '业务平台',
      icon: '',
      content: `确认跳转售后详情？`,
      onOk() {
        let params = {
          _item,
          platform: _item.platform
        }
        SendPlatform(params, CARDTYPE.AFTERSALE);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  handleSearch = () => {
    let { value } = this.refs.searchValue.state;
    if (!value) {
      return message.error('请输入订单编号');
    }
    this.getList({
      orderCode: value
    });
  }

  handleSearchClose = () => {
    this.setSingle('isSearchFlag', false);
  }

  handleEditRemark = (_params = {}, _index, _sellerRemark) => {
    console.log('客服备注 ==>', _params);
    let params = {
      type_drawer: 'DrawerSubRemark',
      _orderCode: _params.orderCode,
      _id: _params.id,
      _sellerRemark,
      _index,
      // _subIndex,
      _refresh: (params) => this.updataList(params)
    }
    this.props.handleLink(params);
  }

  handleSendCard = (_params, _item, _index) => {
    console.log('handleSendCard ==>', _params, _item, _index);
    let { gather } = this.state;
    let { code } = _params;
    let params = {
      _item,
      _index
    };
    switch (code) {
      case 100:
        params.type_drawer = 'DrawerShowInvoice';
        break;
      case 200:
        params.type_drawer = 'DrawerShowAddress';
        params._refresh = (params) => this.updataListAddress(params)
        break;
      case 300:
        params.type_drawer = 'DrawerShowLogistics';
        break;
      case 400:
        params.type_drawer = 'DrawerShowReturn';
        break;
      case 500:
        params = {};
        confirm({
          title: '卡片信息',
          icon: '',
          content: '确认发送订单？',
          onOk() {
            SendCard({ ...gather, _item }, CARDTYPE.ORDER);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        break;
      default:
        break;
    }
    if (Object.keys(params).length > 0)
      this.props.handleLink(params);
  }

  _dealOrderStatus() {
    let { dataTabLists } = this.state;
    let orderStatus = 100;
    let tabListIndex = 0;
    if (dataTabLists.notFinishNum > 0) {
      orderStatus = 300;
      tabListIndex = 1
    }
    this.setState({
      tabListIndex,
      orderStatus
    });
  }

  async componentDidMount() {
    let { index, cIndex } = this.props;
    if (index === cIndex) {
      await this.getList();
      this._dealOrderStatus();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    let { index, cIndex, queryName: nextQueryName } = nextProps;
    let { queryName } = this.props;
    let { isLoadData } = this.state;
    if (index === cIndex) {
      // console.log('componentWillReceiveProps ==> 1', nextQueryName, isLoadData);
      // console.log('componentWillReceiveProps ==> 2', queryName, isLoadData);

      if (nextQueryName && nextQueryName !== queryName) {
        this.setState({
          isSearchFlag: false,
          tabListIndex: 0
        }, () => {
          this.getList({
            orderCode: nextQueryName,
            orderStatus: 100,
            pageIndex: 1
          }, () => {
            this.setState({
              queryName: nextQueryName,
              isSearchFlag: true,
            });
            console.log('this.setState ==>', this.state);
          });
        });
      } else if (!isLoadData) {
        await this.getList();
        this._dealOrderStatus();
      }
    }
  }

  setSingle(_kay, _val) {
    this.setState({
      [_kay]: _val
    })
  }

  setLoading(_val) {
    this.setState({
      pageLoading: _val
    })
  }

  render() {
    let { tabList, tabListIndex, tabIcon, dataTabLists, dataLists, dataListsToggle, totalCount, pageSize, pageIndex, pageLoading, isSearchFlag, pageBtnList, dataListsIcon, queryName, platformOriginal } = this.state;

    // Data tuples
    let TabList = tabList.map((item, index) => {
      return <span className={tabListIndex === index ? 'u-cur' : ''} onClick={() => this.handleTabChange(item, index)} key={index}>{item.name}（{dataTabLists[item.key] || '0'}）</span>
    });

    let TabIconList = tabIcon.map((item, index) => {
      let type = "iconhome_icon_" + item.key + "_24";
      return <Popover key={index} placement="top" content={item.name}><IconFont onClick={() => { this.handleTabIconChange(item) }} type={type} /> </Popover>
    });

    let BoxSearch = isSearchFlag && <div className="m-search-detail animated fadeIn">
      <Input ref='searchValue' size="small" defaultValue={queryName} placeholder="请输入订单编号" />
      <Button onClick={this.handleSearch} className="u-f12" shape="round" size="small" danger>搜索</Button> &nbsp;
      <Button onClick={this.handleSearchClose} size="small" shape="circle" danger icon={<CloseOutlined />} />
    </div>

    let BoxItem = dataLists.map((item, index) => {
      let { orderCode, creatTime, payTime, orderDetailDtoList, postFee, orderCouponDto, afterSalePlatformStatusName, payment, skuCount } = item;

      let toggle = dataListsToggle[index].toggle;
      let saleTop = dataListsToggle[index].saleTop;
      let saleBttom = dataListsToggle[index].saleBttom;

      let { receiveName, receiveMobile, provinceName, cityName, areaName, address, sellerRemark } = orderDetailDtoList && orderDetailDtoList[0];

      let ReceivingInformation = `${receiveName}，${receiveMobile}，${provinceName}${cityName}${areaName}${address}`;

      let BoxSubItem = (
        <div key={item.id} className={toggle ? 'm-detail animated fadeIn' : 'm-detail hide'}>
          <div className="m-left">
            <div className="m-price">
              <p>
                <span className="u-s1">¥ {formatNumber(payment)}</span>
                <span className="u-s2">（共{skuCount}个，含邮费{formatNumber(postFee)}）</span>
              </p>
              {item.platform !== 100 && <OrderMenu
                arrow={saleTop}
                handleVisibleChange={() => this.handleVisibleChange('saleTop', index)}
                dataList={orderCouponDto} />}
            </div>
            <div className="m-adress">
              <HomeUserIcon
                name={ReceivingInformation}
                label="收货信息:"
                labelClass="u-s1"
                type="copy"
                complete
              />
              <p className="m-remark">
                <span className="u-s1">客服备注:</span>
                <span className="u-remark">{sellerRemark && sellerRemark.substr(0, 300)}</span>
                <IconFont
                  className="u-f16 u-p"
                  type="iconhome_icon_beizhu_24"
                  onClick={() => this.handleEditRemark(item, index, sellerRemark)}
                />
              </p>
            </div>
            {/* Goods inform */}
            {orderDetailDtoList.map((sub, subIndex) => (
              <div className="m-goods" key={subIndex}>
                <div className="m-dgoods">
                  <div className="m-img">
                    <p><img src={sub.goodsUrl} alt="Goods" /></p>
                    {/* <p>{sub.deliveryStateName}</p> */}
                  </div>
                  <div className="m-name">
                    <p>{sub.goodsName}</p>
                    <p>{sub.goodsSpec}</p>
                    <p>实收：<i>￥{formatNumber(sub.goodsPayment)} (共{sub.goodsQty || '-'}个)</i></p>
                    <HomeUserIcon
                      name={sub.goodsSku}
                      label="编号："
                      type="copy"
                    />
                  </div>
                </div>
                {item.platform !== 100 && <div className="m-preferential">
                  <OrderMenu
                    arrow={saleBttom}
                    handleVisibleChange={() => this.handleVisibleChange('saleBttom', index)} dataList={sub.orderGoodsCouponDtoList} />
                </div>}
              </div>
            ))}
            {/* AfterSale inform */}
            {item.platform === 200 && afterSalePlatformStatusName && <div className="m-jump-detail">
              <span>{afterSalePlatformStatusName}</span>
              <span><Button onClick={() => this.handleAftersale(item)} size="small" danger>查看详情</Button></span>
            </div>}
          </div>
          <div className="m-right">
            <div className="m-state">
              <p className="u-s1">{item.orderStatusName || '暂无'}</p>
              <p><i>{item.afterSaleStatusName}</i></p>
            </div>
            <div className="m-wbtn">
              {pageBtnList.map((gard, gradindex) => {
                return (gard.hidden && gard.hidden.includes(item.orderStatus)) ||
                  (gard.show && !gard.show.includes(item.orderStatus)) ||
                  (gard.cusfield && !item[gard.cusfield]) ||
                  (gard.comFieldValue && gard.comFieldValue === item[gard.comField])
                  ? ''
                  : <Button
                    key={gradindex}
                    size="small"
                    disabled={platformOriginal !== item.platform}
                    onClick={() => this.handleSendCard(gard, item, index)}
                    danger
                  >{gard.name}</Button>
              })}
            </div>
          </div>
        </div>
      );

      return (<div className="m-box" key={item.id}>
        <div className="m-overview">
          <div>
            <HomeUserIcon
              name={orderCode}
              type="copy"
            />
            <p>{formatDatetime(creatTime)} 下单 {formatDatetime(payTime)} 付款</p>
          </div>
          <div className="m-btn">
            <p>
              {dataListsIcon && dataListsIcon.map((sub, index) => (
                item[sub.key] === sub.kcode &&
                <Popover key={index} placement="top" content={sub.fullName || sub.name}><span className="Ubtn2"
                  onClick={() => this.handleDataListsIconChange(sub, item)}>{sub.name}</span></Popover>
              ))}
            </p>
            <p onClick={() => this.handleToggleDetail(index)}>
              <ToggleArrow type={toggle} />
            </p>
          </div>
        </div>
        {BoxSubItem}
      </div>)
    });

    let BoxMain = !BoxItem.length ? <HomeNull /> : BoxItem;

    return (
      <div className="order-inform">
        <div className="m-order-tab">
          {TabList}
          <span className="u-search">
            {TabIconList}
          </span>
        </div>
        {BoxSearch}
        <div className="m-order-list">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>
        <div className="m-home-footer">
          {totalCount > pageSize && <Pagination
            size="small"
            total={totalCount}
            defaultPageSize={pageSize}
            showSizeChanger={false}
            current={pageIndex}
            onChange={this.handlePageChange}
            showQuickJumper
          />}
        </div>
      </div>
    )
  }
}

export default HomeOrderList;
