// common keys shared by frontend and backend
export const APP_KEY = "36891137";
export const KEY_APP_KEY = "appKey";
export const KEY_TOKEN = "token";
export const KEY_USER_ID = "userId";
export const KEY_USER_INFORM = "CC_inform";
export const KEY_QUERY = "CC_query";
export const KEY_CUS_QUERY = "CC_customer_query";
export const KEY_JWT = "jwt";

export const CARDTYPE = {
  ORDER: "order",
  ORDER_LIST: "orderList",
  GOODS: "goods",
  INVOICE: "invoice",
  AFTERSALE: "aftersale",
  LOGISTICS: "logistics",
};

/**
 * Platform-source-mapping
 * @description
 * DC(100, "dc", "钻卡"),
 * YESTAE(200, "yestae", "益购商城"),
 * FANS_CLUB(300, "fans_club", "虎族俱乐部")
 * RETAIL(400, "retail", "益伙伴小程序")
 * */
export const MAP_PLATFORM = {
  100: [200, "APP_IOS", "iOS客户端"],
  200: [200, "APP_ANDROID", "Android客户端"],
  300: [100, "APPLETS_DC", "钻卡小程序"],
  400: [200, "YESTAE_APPLETS", "益购商城小程序"],
  500: [100, "DC_PC", "钻卡PC"],
  600: [200, "YESTAE_PC", "益购商城PC"],
  700: [300, "FANS_CLUB_APPLETS", "虎族俱乐部小程序"],
  800: [300, "FANS_CLUB_IOS", "虎族俱乐部iOS客户端"],
  900: [300, "FANS_CLUB_ANDROID", "虎族俱乐部Android客户端"],
  1000: [400, "RETAIL_APPLETS", "益伙伴小程序"],
};

export const PC_CODE_LIST = [500, 600];

// Customize code values temporarily
export const CUS_CODE_LIST = [700, 800, 900, 1000];

// Business platform
const DC_URL_TEST = "https://dc-manage-test.yestae.com/#/";
const DC_URL_PROD = "https://dc-manage.yestae.com/#/";

const YESTAE_URL_TEST = "https://mall-operate-test.yestae.com/#/";
const YESTAE_URL_PROD = "https://ebs-operate.yestae.com/#/";

const TEST = {
  100: DC_URL_TEST,
  200: YESTAE_URL_TEST,
};

const PROD = {
  100: DC_URL_PROD,
  200: YESTAE_URL_PROD,
};
const { REACT_APP_MODE } = process.env;

export const URL_LIST = REACT_APP_MODE === "prod" ? PROD : TEST;
