import { KEY_TOKEN, KEY_USER_ID, KEY_USER_INFORM, KEY_QUERY, KEY_CUS_QUERY } from '../constant/index';
import { getQuerySearch, getToParamString } from "@/utils";

/**
 * @desc  get item from storage
 * @param  key [String] item key
 * @param  storage [Storage] localStorage or sessionStorage
 * @return  [Object|String|Number|Array|Boolean|Number] item value
 */
export function getItem(key, storage = localStorage) {
  return storage.getItem(key)
}
/**
 * @desc  get items from storage
 * @param  keys [Array] keys of items, eg: ['token', 'userId']
 * @param  storage [Storage] localStorage or sessionStorage
 * @return  [Object] values of items, eg: { token: 'xxxx', userId: 'aaaaa' }
 */
export function getItems(keys = [], storage = localStorage) {
  if (keys.length === 0) {
    return {}
  }
  const values = {}
  keys.forEach(key => {
    values[key] = storage.getItem(key)
  })
  return values
}

/**
* @desc  set item to storage
* @param  key [String] item key, eg: 'token'
* @param  value [String] item value, eg: 'xxx'
* @param  storage [Storage] localStorage or sessionStorage
* @return
*/
export function setItem(key, value, storage = localStorage) {
  if (!key || typeof key !== 'string') {
    return;
  }
  let val = value;
  if (typeof value === 'object') {
    val = JSON.stringify(value);
  }
  storage.setItem(key, val);
}

export function setLoginInform(inform) {
  let { accountDto = {} } = inform;
  setItem(KEY_TOKEN, accountDto.token);
  setItem(KEY_USER_ID, accountDto.id);
  setItem(KEY_USER_INFORM, accountDto);
};

export function setLoginQuery(inform = {}) {
  let { customQuery = {} } = inform;

  // Filter transcode parameters
  let hash = window.location.hash;
  let params = getQuerySearch(hash, ['qiyuCustomer']);
  if (!Object.keys(params).length) return this.setLoading(false);

  // Filter blending parameters
  params.queryString = getToParamString(params, ['signature', 'appId']);

  if (!customQuery.MobilePhone) customQuery = params;

  setItem(KEY_QUERY, customQuery);
  setItem(KEY_CUS_QUERY, decodeURIComponent(customQuery.qiyuCustomer || '{}'));
};

export function getLoginInform() {
  return {
    [KEY_TOKEN]: getItem(KEY_TOKEN),
    [KEY_USER_ID]: getItem(KEY_USER_ID)
  }
};

export function getCusQuery() {
  // let data = getItem(KEY_CUS_QUERY);
  // if(!data) {
  //   setLoginQuery();
  //   data = getItem(KEY_CUS_QUERY);
  // }
  setLoginQuery();
  let data = getItem(KEY_CUS_QUERY);
  return data && JSON.parse(data);
};

export function getAllQuery() {
  let data = getItem(KEY_QUERY);
  return data && JSON.parse(data);
};
