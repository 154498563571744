import BaseAuth from '@/config/network/BaseAuth';
import { base } from './service-base';

// 获取 Token
export function getList(params) {
  return BaseAuth({
    url: `${base}/city/getList`,
    method: 'POST',
    data: params
  });
}

export function getWeiXinMediaId(params) {
  return BaseAuth({
    url: `${base}/weiXin/getWeiXinMediaId`,
    method: 'POST',
    data: params
  });
}
