import React from 'react';
import { getAddServicesDetailList } from '@/api/home';
import { formatDate } from '@/utils';
import Data from './template';

import HomeLoading from '@c/HomeLoading';
import HomeNull from '@c/HomeNull';

export default class DrawerShowBase extends React.Component {
  constructor(props) {
    super(props);
    let { gather, visible } = this.props;
    let drawer = gather.type_drawer;
    let dataList = gather.dataRecordList || [];
    let copyVale = "";
    let firstItem = dataList[dataList.length - 1] || {};

    dataList.map(item => {
      let type = item.userId === item.operatorId ? '(用户自主修改)' : '(后台修改)';
      return copyVale += `${formatDate(item.createTime)} 原手机 修改为 ${item.targetMobile}${type}\n`;
    })

    copyVale += `${formatDate(gather.registerTime)} ${firstItem.sourceMobile || '-'} 注册成功`;

    this.state = {
      dataHead: Data[drawer],
      dataList: drawer === 'DrawerShowPhone' ? [] : dataList,
      visible,
      gather,
      drawer,
      pageLoading: false,
      copyVale
    }

    console.log('dataList ==>', gather);
  }

  getList = async () => {
    this.setLoading(true);
    try {
      let { gather: { memberId, addedServiceId, platform } = {} } = this.state;
      let copyVale = '';
      let result = await getAddServicesDetailList({ memberId, addedServiceId, platform });

      result && result.map((item, index) => {
        return copyVale += `${formatDate(item.beginTime)} 开通 ${result.length - 1 === index ? '（第一次）' : ''} ${formatDate(item.endTime)} 已到期 \n`;
      })

      this.setState({
        dataList: result || [],
        pageLoading: false,
        copyVale
      })
    } catch (error) {
      this.setLoading(false);
    }

  }

  componentDidMount() {
    let { visible, drawer } = this.state;
    visible && drawer === 'DrawerShowPhone' && this.getList();
  }

  setLoading(_val) {
    this.setState({
      pageLoading: _val
    })
  }

  render() {
    let { dataList, gather, dataHead, drawer, pageLoading } = this.state;
    let nearGather = (dataList && dataList[0]) || {};
    let firstGather = (dataList && dataList[dataList.length - 1]) || {};

    let BoxRegistered = <p>{gather && formatDate(gather.registerTime)} <span className="Ualink">{firstGather.sourceMobile || '-'}</span> 注册成功</p>;

    let BoxItem = dataList && dataList.map((item, index) => {
      let type = item.userId === item.operatorId ? '(用户自主修改)' : '(后台修改)';
      return (<div><p key={item.id}>{formatDate(item.createTime)} 原手机 <span className="Ualink">{item.sourceMobile}</span> 修改为 <span className="Ualink">{item.targetMobile}</span> {type}</p>{dataList.length - 1 === index && BoxRegistered}</div>)
    });

    console.log('BoxItemAdd ==>', dataList);

    let BoxItemAdd = dataList && dataList.map((item, index) => {
      return <p key={item.id}>
        <span>{formatDate(item.beginTime) || '-'} 开通 {dataList.length - 1 === index && '（第一次）'}</span> &emsp; <span>{formatDate(item.endTime) || '-'} 已到期</span>
      </p>
    })

    let BoxMain = drawer === 'DrawerShowBase' ?
      BoxItem && BoxItem.length ? BoxItem : <HomeNull /> :
      BoxItemAdd && BoxItemAdd.length ? BoxItemAdd : <HomeNull />;

    let BoxHead = drawer === 'DrawerShowBase' ? <div className="m-fr">
      <span>{dataHead.lableLeft}：{nearGather.sourceMobile || '-'}</span>
      <span>{dataHead.lableRight}：{nearGather.targetMobile || '-'}</span>
    </div> : <div className="m-fr">
      <span>{dataHead.lableLeft}：{formatDate(nearGather.beginTime) || '-'}</span>
      <span>{dataHead.lableRight}：{formatDate(nearGather.endTime) || '-'}</span>
    </div>

    return (
      <div className="drawer-yc-wrapper">
        <div className="m-drawer-top">
          <p className="u-p1">{dataHead.name}：</p>
          {BoxHead}
        </div>
        <div className="honour-detail">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>
      </div>
    )
  };
}
