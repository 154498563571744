import React from 'react';
import { Pagination } from 'antd';

// Tool && Api
import { formatNumber, formatDatetime } from '@/utils';
import { getMemberDepositDetail } from '@/api/home';

import HomeLoading from '@c/HomeLoading';
import HomeNull from '@c/HomeNull';

export default class DrawerShowList extends React.Component {
  constructor(props) {
    super(props);

    let { gather, visible } = this.props;
    this.state = {
      dataLists: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: null,
      gather,
      visible,
      pageLoading: true,
      depositAmount: null
    }

    console.log('this.props ==>', this.props);
  }

  async getList(_params = {}) {
    this.setLoading(true);
    let { pageIndex, pageSize, gather: { memberId, platform }, depositAmount } = this.state;
    let params = {
      memberId,
      platform,
      pageIndex: _params.pageIndex || pageIndex,
      pageSize: _params.pageSize || pageSize,
    };
    try {
      let { result, totalCount } = await getMemberDepositDetail(params);
      let dataLists = result || [];
      depositAmount = params.pageIndex === 1 ? dataLists && dataLists[0] && dataLists[0].depositAmount : depositAmount;
      this.setState({
        dataLists,
        totalCount,
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        pageLoading: false,
        depositAmount
      });
    } catch (error) {
      this.setLoading(false);
    }
  }

  handlePageChange = (pageIndex, pageSize) => {
    let params = {
      pageIndex,
      pageSize
    }
    this.getList(params);
  }

  setLoading(_val) {
    this.setState({
      pageLoading: _val
    })
  }

  componentDidMount() {
    let { visible } = this.state;
    visible && this.getList();
  }

  render() {
    let { dataLists, totalCount, pageSize, pageIndex, pageLoading, depositAmount } = this.state;

    let BoxItem = dataLists.map(item => {
      let { bizType } = item;
      let amountPrefix = [1, 2, 4].includes(bizType) ? '+' : '-';
      return (
        <div className="m-box-item" key={item.id}>
          <div className="u-p1"><span>{item.bizTypeName}{item.tradeNo ? '-' + item.tradeNo : ''}</span><span className={amountPrefix === '+' ? 'u-error' : 'u-span'}>{amountPrefix}{formatNumber(item.amount)}</span></div>
          <div className="u-p2"><span>{formatDatetime(item.finishPayTime)}</span></div>
        </div>
      )
    });
    let BoxMain = !BoxItem.length ? <HomeNull /> : BoxItem;

    // let depositAmount = dataLists && dataLists[0] && dataLists[0].depositAmount;

    return (
      pageSize && <div className="drawer-yc-wrapper">
        <div className="m-drawer-top">
          <p className="u-p1">预存款详情：</p>
          <p className="u-p2">预存款余额：<i>{formatNumber(depositAmount)}</i></p>
        </div>
        <div className="m-drawer-main">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>
        <div className="m-drawer-footer">
          {totalCount > pageSize && <Pagination
            size="small"
            total={totalCount}
            defaultPageSize={pageSize}
            showSizeChanger={false}
            showQuickJumper
            current={pageIndex}
            onChange={this.handlePageChange}
          />}
        </div>
      </div>
    )
  };
}
