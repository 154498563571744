import { message } from "antd";
import { getCusQuery, getAllQuery } from "./storage";
import { getWeiXinMediaId } from "@/api/base";
import { URL_LIST } from "@/constant";
import { getToParamString } from "@/utils";

function SendMessage(data) {
  window.parent.postMessage(data, "*");
  message.success("发送成功");
}

// Send card information to the applet
export function SendCard(_params, _type, _callback) {
  console.log("SendCard ==> _params", _params);
  console.log("SendCard ==> _type", _type);
  console.log("SendCard ==>  getCusQuery", getCusQuery());

  // Data init
  let { _item = {}, _goods = {}, memberId, platform } = _params;
  let { id, orderCode, payment, skuCount, orderDetailDtoList = [] } = _item;
  let { goodsUrl } = orderDetailDtoList[0] || {};

  let { cc_source_code, cc_card_order_url, cc_card_good_url } = getCusQuery();

  // 钻卡订单(PC)
  let DcOrder = {
    picture: goodsUrl,
    url: `${cc_card_order_url}${orderCode}`,
    title: orderCode,
    desc: `共${skuCount}商品，合计 ¥${payment}`,
    price: "",
    showCustomMsg: 1,
  };

  let DcGoodsUrl =
    _goods.menuType === 3
      ? `${cc_card_good_url}?prodCode=${_goods.goodsSku}`
      : `${cc_card_good_url}/goods?prodCode=${_goods.goodsSku}&tabType=${_goods.menuType}`;

  // 钻卡商品(PC)
  let DcGoods = {
    title: _goods.goodsName,
    desc: _goods.goodsSpec,
    picture: _goods.goodsUrlCustom,
    url: DcGoodsUrl,
    price: "¥" + _goods.goodsPriceCustom,
    showCustomMsg: 1,
  };

  // 钻卡订单(小程序)
  let DcOrderWx = {
    msgtype: "miniprogrampage",
    title: orderCode,
    name: "大益商城钻石专区",
    headimg: goodsUrl,
    thumburl: goodsUrl,
    pagepath: `${
      cc_card_order_url && cc_card_order_url.split("?")[0]
    }?orderNo=${orderCode}`,
  };
  // 钻卡商品(小程序)
  let DcGoodsWx = {
    msgtype: "miniprogrampage",
    title: _goods.goodsName,
    name: "大益商城钻石专区",
    headimg: _goods.goodsUrlCustom,
    thumburl: _goods.goodsUrlCustom,
    pagepath: `${cc_card_order_url && cc_card_good_url.split("?")[0]}?type=${
      _goods.menuType
    }&prodId=${_goods.goodsSku}`,
  };

  // 益购商品(PC)
  let YestaeGoods = {
    title: _goods.goodsName,
    desc: _goods.goodsSpec,
    picture: _goods.goodsUrlCustom,
    url: `${cc_card_good_url}${_goods.goodsId}`,
    price: "¥" + _goods.goodsPriceCustom,
    showCustomMsg: 1,
  };
  // 益购订单(PC)
  let YestaeOrder = {
    picture: goodsUrl,
    url: `${cc_card_order_url}${id}`,
    title: orderCode,
    desc: `共${skuCount}商品，合计 ¥${payment}`,
    price: "",
    showCustomMsg: 1,
  };

  // 益购商品(APP)
  let GoodsParams = {
    cc_card_type: _type,
    cc_card_id: _goods.goodsId || id,
    cc_card_coin: _goods && _goods.coinState,
  };

  let YestaeGoodsUrl = `${
    cc_card_good_url && cc_card_good_url.split("?")[0]
  }?goodsId=${_goods.goodsId}`;
  let YestaeOrderUrl = `${URL_LIST[platform]}dynamic/orderDetail/${orderCode}`;

  let AppBaseParams = getToParamString(GoodsParams);

  console.log(
    "getToParamString ==>",
    YestaeGoodsUrl,
    AppBaseParams,
    URL_LIST[platform]
  );
  let YestaeGoodsApp = {
    title: _goods.goodsName,
    desc: _goods.goodsSpec,
    picture: _goods.goodsUrlCustom,
    url: `${YestaeGoodsUrl}&${AppBaseParams}`,
    price:
      (_goods.goodsPriceCustom && _goods.goodsPriceCustom.indexOf("¥") !== -1
        ? ""
        : "¥") + _goods.goodsPriceCustom,
    showCustomMsg: 1,
  };
  // 益购订单(APP)
  let YestaeOrderApp = {
    picture: goodsUrl,
    url: `${YestaeOrderUrl}?${AppBaseParams}`,
    title: orderCode,
    desc: `共${skuCount}商品，合计 ¥${payment}`,
    price: "",
    showCustomMsg: 1,
  };

  // 益购商品(小程序)
  let YestaeGoodsWx = {
    headimg: _goods.goodsUrlCustom,
    msgtype: "miniprogrampage",
    title: _goods.goodsName,
    name: "大益商城",
    thumburl: _goods.goodsUrlCustom,
    pagepath: `${YestaeGoodsUrl}`,
  };
  // 益购订单(小程序)
  let YestaeOrderWx = {
    msgtype: "miniprogrampage",
    title: orderCode,
    name: "大益商城",
    headimg: goodsUrl,
    thumburl: goodsUrl,
    pagepath: `${
      cc_card_order_url && cc_card_order_url.split("?")[0]
    }?orderId=${id}`,
  };

  // The data set
  let dataGather = {
    600: {
      order: YestaeOrder,
      invoice: YestaeOrder,
      logistics: YestaeOrder,
      aftersale: YestaeOrder,
      goods: YestaeGoods,
    },
    500: {
      order: DcOrder,
      invoice: DcOrder,
      logistics: DcOrder,
      aftersale: DcOrder,
      goods: DcGoods,
    },
    400: {
      order: YestaeOrderWx,
      invoice: YestaeOrderWx,
      logistics: YestaeOrderWx,
      aftersale: YestaeOrderWx,
      goods: YestaeGoodsWx,
    },
    300: {
      order: DcOrderWx,
      invoice: DcOrderWx,
      logistics: DcOrderWx,
      aftersale: DcOrderWx,
      goods: DcGoodsWx,
    },
    200: {
      order: YestaeOrderApp,
      invoice: YestaeOrderApp,
      logistics: YestaeOrderApp,
      aftersale: YestaeOrderApp,
      goods: YestaeGoodsApp,
    },
    100: {
      order: YestaeOrderApp,
      invoice: YestaeOrderApp,
      logistics: YestaeOrderApp,
      aftersale: YestaeOrderApp,
      goods: YestaeGoodsApp,
    },
  };

  if (!cc_source_code) {
    message.error("未识别来源");
    return _callback && _callback();
  }

  let data = dataGather[cc_source_code][_type];

  // Small program card information is handled separately
  if ([300, 400].includes(parseInt(cc_source_code))) {
    let params = {
      fileType: "image",
      memberId,
      platform,
      urlPath: goodsUrl || _goods.goodsUrlCustom,
    };
    getWeiXinMediaId(params).then((res) => {
      data.thumb_media_id = res && res.weiXinMediaId;
      if (!data.thumb_media_id) {
        message.error("发送失败");
      } else {
        SendMessage(data);
      }
      console.log("Send Card ==> WX-SUCCESS", data, _type);
      _callback && _callback();
    });
  } else {
    console.log("Send Card ==> SUCCESS", data, _type);
    SendMessage(data);
    _callback && _callback();
  }
}

// Send card information to the business backend
export function SendPlatform(_params, _type) {
  console.log("SendPlatform ==>", _params, _type);

  // Data init
  let { platform, _goods = {}, _item = {} } = _params;
  let { goodsId } = _goods;
  let { id, invoiceId, orderCode } = _item;

  if (!_type || !platform) return message.error("缺少Type||Platform值");

  // The data set
  let dataGather = {
    100: {
      goods: "dynamic/goodDetail/" + goodsId,
      invoice: "dynamic/invoiceDetail/" + invoiceId,
      order: "dynamic/orderDetail/" + id,
      orderList: "orders/orders/list",
    },
    200: {
      goods: "dynamic/goodDetail/" + goodsId,
      invoice: "dynamic/invoiceDetail/" + invoiceId,
      order: "dynamic/orderDetail/" + orderCode,
      orderList: "order/order/order",
      aftersale: "dynamic/aftersaleDetail/" + _item.afterSaleId,
    },
  };

  let URL = URL_LIST[platform] + dataGather[platform][_type];

  console.log("PURL[_code] ==>", URL_LIST, platform, _type, URL);

  // const OPEN = window.open('about:blank');
  // return OPEN.location.href = URL_LIST[platform] + dataGather[platform][_type];

  window.open(
    URL,
    "_blank",
    "width=700,height=400,menubar=yes,toolbar=yes,status=yes,scrollbars=yes"
  );
}

// Reuse wechat price logic
export function DealGoodsPrice(userType, goods) {
  let { MobilePhone } = getAllQuery();
  let generalCanBuy = goods.levelRuleList
    ? goods.levelRuleList.includes("1") || goods.levelRuleList.includes(1)
    : false; //普通可买
  let goldCardCanBuy = goods.levelRuleList
    ? goods.levelRuleList.includes("2") || goods.levelRuleList.includes(2)
    : false; //金卡可买
  let openUPVasCanBuy = goods.levelRuleList
    ? goods.levelRuleList.includes("3") || goods.levelRuleList.includes(3)
    : false; //尊享可买

  console.log("DealGoodsPrice ==>", userType, goods, MobilePhone);

  let asc = function (a, b) {
    return a - b;
  };
  if (!MobilePhone) {
    // 供给人群【1普通，2金卡，3尊享】
    // 只供给 2
    if (!generalCanBuy && goldCardCanBuy && !openUPVasCanBuy) {
      if (goods.coinState === 3) {
        //混合商品
        let money = goods.gcPrice - goods.yestaeCurrencyCount;
        money = parseFloat(money.toFixed(2));
        goods.intPrice = parseInt(money);
        goods.floatPrice = (money + "").split(".")[1] || "";
      } else {
        goods.intPrice = parseInt(goods.gcPrice);
        goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
      }
      // 供给 3
    } else if (!generalCanBuy && !goldCardCanBuy && openUPVasCanBuy) {
      if (goods.coinState === 3) {
        //混合商品
        let money = goods.vasPrice - goods.yestaeCurrencyCount;
        money = parseFloat(money.toFixed(2));
        goods.intPrice = parseInt(money);
        goods.floatPrice = (money + "").split(".")[1] || "";
      } else {
        goods.intPrice = parseInt(goods.vasPrice);
        goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
      }
      // 供给 2 3
    } else if (!generalCanBuy && (goldCardCanBuy || openUPVasCanBuy)) {
      if (goods.vasPrice < goods.gcPrice) {
        if (goods.coinState === 3) {
          //混合商品
          let money = goods.vasPrice - goods.yestaeCurrencyCount;
          money = parseFloat(money.toFixed(2));
          goods.intPrice = parseInt(money);
          goods.floatPrice = (money + "").split(".")[1] || "";
        } else {
          goods.intPrice = parseInt(goods.vasPrice);
          goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
        }
      } else {
        if (goods.coinState === 3) {
          //混合商品
          let money = goods.gcPrice - goods.yestaeCurrencyCount;
          money = parseFloat(money.toFixed(2));
          goods.intPrice = parseInt(money);
          goods.floatPrice = (money + "").split(".")[1] || "";
        } else {
          goods.intPrice = parseInt(goods.gcPrice);
          goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
        }
      }
      // 供给 1 2 3 或者空的时候
    } else {
      let priceArry = [goods.vasPrice, goods.gcPrice, goods.ncPrice];
      let bestPrice = priceArry.sort(asc)[0] || 0;
      if (goods.coinState === 3) {
        priceArry.push(bestPrice - goods.yestaeCurrencyCount);
        bestPrice = priceArry.sort(asc)[0];
      }
      bestPrice = parseFloat(bestPrice.toFixed(2));

      goods.intPrice = parseInt(bestPrice);
      goods.floatPrice = (bestPrice + "").split(".")[1] || "";
    }
  } else {
    if (userType === 2) {
      //金卡会员
      if (goods.isOpenUPVas === 1) {
        //尊享会员
        // 供给人群【1普通，2金卡，3尊享】
        // 只供给 2
        if (!generalCanBuy && goldCardCanBuy && !openUPVasCanBuy) {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.gcPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.gcPrice);
            goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
          }
          // 供给 3
        } else if (!generalCanBuy && !goldCardCanBuy && openUPVasCanBuy) {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.vasPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.vasPrice);
            goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
          }
          // 供给 2 3
        } else if (!generalCanBuy && (goldCardCanBuy || openUPVasCanBuy)) {
          if (goods.vasPrice < goods.gcPrice) {
            if (goods.coinState === 3) {
              //混合商品
              let money = goods.vasPrice - goods.yestaeCurrencyCount;
              money = parseFloat(money.toFixed(2));
              goods.intPrice = parseInt(money);
              goods.floatPrice = (money + "").split(".")[1] || "";
            } else {
              goods.intPrice = parseInt(goods.vasPrice);
              goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
            }
          } else {
            if (goods.coinState === 3) {
              //混合商品
              let money = goods.gcPrice - goods.yestaeCurrencyCount;
              money = parseFloat(money.toFixed(2));
              goods.intPrice = parseInt(money);
              goods.floatPrice = (money + "").split(".")[1] || "";
            } else {
              goods.intPrice = parseInt(goods.gcPrice);
              goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
            }
          }
          // 供给 1 2 3 或者空的时候
        } else {
          let priceArry = [goods.vasPrice, goods.gcPrice, goods.ncPrice];
          let bestPrice = priceArry.sort(asc)[0];
          if (goods.coinState === 3) {
            priceArry.push(bestPrice - goods.yestaeCurrencyCount);
            bestPrice = priceArry.sort(asc)[0];
          }
          bestPrice = parseFloat(bestPrice.toFixed(2));
          goods.intPrice = parseInt(bestPrice);
          goods.floatPrice = (bestPrice + "").split(".")[1] || "";
        }
      } else {
        //用户只是金卡
        // 供给人群【1普通，2金卡，3尊享】
        // 供给 3 展示尊享价
        if (!generalCanBuy && !goldCardCanBuy && openUPVasCanBuy) {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.vasPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.vasPrice);
            goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
          }
          // case1：只供给 2 展示金卡价 case2：只供给 2 3 展示尊享价  case3：只供给 1 2 3 或者空的时候
        } else {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.gcPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.gcPrice);
            goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
          }
        }
      }
    } else {
      if (goods.isOpenUPVas === 1) {
        //用户仅是尊享
        // 供给人群【1普通，2金卡，3尊享】
        // 只供给 2
        if (!generalCanBuy && goldCardCanBuy && !openUPVasCanBuy) {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.gcPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.gcPrice);
            goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
          }
          // case1：只供给 2 展示金卡价 case2：只供给 2 3 展示尊享价  case3：只供给 1 2 3 或者空的时候
        } else {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.vasPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.vasPrice);
            goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
          }
        }
      } else {
        //非会员
        // 供给人群【1普通，2金卡，3尊享】
        // 只供给 2
        if (!generalCanBuy && goldCardCanBuy && !openUPVasCanBuy) {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.gcPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.gcPrice);
            goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
          }
          // 供给 3
        } else if (!generalCanBuy && !goldCardCanBuy && openUPVasCanBuy) {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.vasPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.vasPrice);
            goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
          }
          // 供给 2 3
        } else if (!generalCanBuy && (goldCardCanBuy || openUPVasCanBuy)) {
          if (goods.vasPrice < goods.gcPrice) {
            if (goods.coinState === 3) {
              //混合商品
              let money = goods.vasPrice - goods.yestaeCurrencyCount;
              money = parseFloat(money.toFixed(2));
              goods.intPrice = parseInt(money);
              goods.floatPrice = (money + "").split(".")[1] || "";
            } else {
              goods.intPrice = parseInt(goods.vasPrice);
              goods.floatPrice = (goods.vasPrice + "").split(".")[1] || "";
            }
          } else {
            if (goods.coinState === 3) {
              //混合商品
              let money = goods.gcPrice - goods.yestaeCurrencyCount;
              money = parseFloat(money.toFixed(2));
              goods.intPrice = parseInt(money);
              goods.floatPrice = (money + "").split(".")[1] || "";
            } else {
              goods.intPrice = parseInt(goods.gcPrice);
              goods.floatPrice = (goods.gcPrice + "").split(".")[1] || "";
            }
          }
          // 供给 1 2 3 或者空的时候
        } else {
          if (goods.coinState === 3) {
            //混合商品
            let money = goods.ncPrice - goods.yestaeCurrencyCount;
            money = parseFloat(money.toFixed(2));
            goods.intPrice = parseInt(money);
            goods.floatPrice = (money + "").split(".")[1] || "";
          } else {
            goods.intPrice = parseInt(goods.ncPrice);
            goods.floatPrice = (goods.ncPrice + "").split(".")[1] || "";
          }
        }
      }
    }
  }
}

export function DealPcGoodsPrice(userType, goods) {
  if (userType === 2 || goods.isOpenUPVas) {
    let price =
      (!goods.isOpenUPVas
        ? goods.gcPrice
        : goods.gcPrice > goods.vasPrice
        ? goods.vasPrice
        : goods.gcPrice
      ).toString() || "0.00";
    goods.intPrice = price.split(".")[0];
    goods.floatPrice = price.split(".")[1];
  } else {
    let price = goods.ncPrice.toString() || "0.00";
    goods.intPrice = price.split(".")[0];
    goods.floatPrice = price.split(".")[1];
  }
}
