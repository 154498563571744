import { Row, Col } from 'antd';
import { formatNumber, formatDate } from '@/utils';
import { HomeMoreYestae, HomeMoreDc } from './template';

const FormattedData = (_type, _val) => {
  switch (_type) {
    case 'money':
      return formatNumber(_val)
    case 'time':
      return _val ? formatDate(_val) : '-';
    default:
      return _val || '-'
  }
}

const BoxItem = (props) => {
  let { item, index, handleChage, gather } = props;
  return (
    <Col xs={12} sm={8} md={6} key={index}>
      <span
        onClick={() => item.link && handleChage('DrawerShowList', item.key)}
        className={item.link ? 'Ualink u-span' : 'u-span'}>
        {item.name}：{FormattedData(item.special, gather[item.key])} {item.key === 'registerDay' ? '天' : ''}
      </span>
    </Col>)
}

const HomeMoreList = (props) => {
  let { gather = {}, handleLink } = props;
  let platformType = gather && gather.platform === 100;

  const _handleLink = async (_params, _key) => {
    let type = _key === 'couponAmount' ? 'DrawerShowRolls' : _params;
    let params = {
      type_drawer: type
    }
    console.log('_params ==>', _params, _key);
    handleLink(params);
  }

  const Dc = (
    <Row>
      {
        HomeMoreDc.map((item, index) => {
          return <BoxItem
            key={index}
            item={item}
            index={index}
            gather={gather}
            handleChage={_handleLink} />
        })
      }
    </Row>
  );

  const Yestae = (
    <Row>
      {
        HomeMoreYestae.map((item, index) => {
          return <BoxItem
            item={item}
            key={index}
            index={index}
            gather={gather}
            handleChage={_handleLink} />
        })
      }
    </Row>
  );

  return (
    <div className="m-box-list animated fadeIn">
      {platformType ? Dc : Yestae}
    </div>
  )
}

export default HomeMoreList;
