import React from 'react';
import img from '@/assets/imgs/404.png';

class NoMatch extends React.Component {
  state = {
    animated: '',
  };
  enter = () => {
    this.setState({ animated: 'hinge' });
  };
  render() {
    return (
      <div
        style={{ height: '100%', background: '#ececec', overflow: 'hidden', textAlign: 'center', paddingTop: '100px' }}
      >
        <img
          src={img}
          alt="404"
          className={`animated swing ${this.state.animated}`}
          onMouseEnter={this.enter}
        />
      </div>
    );
  }
}

export default NoMatch;
