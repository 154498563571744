export const HomeMoreYestae = [
  {
    name: '预存款余额',
    key: 'depositAmount',
    special: 'money',
    link: true
  },
  {
    name: '受益券余额',
    key: 'couponAmount',
    special: 'money',
    link: true
  },
  {
    name: '总消费额',
    key: 'totalAmount',
    special: 'money'
  },
  {
    name: '平均客单价',
    key: 'averagePrice',
    special: 'money'
  },
  {
    name: '注册时间',
    key: 'registerDay'
  },
  {
    name: '最近交易',
    key: 'latestTradingTime',
    special: 'time'
  },
  {
    name: '实名生肖',
    key: 'bornName'
  },
  {
    name: '身份证',
    key: 'idNo'
  },
  {
    name: '银行卡',
    key: 'bankCardNo'
  }
];

export const HomeMoreDc = [
  {
    name: '预存款余额',
    key: 'depositAmount',
    special: 'money',
    link: true
  },
  {
    name: '总消费额',
    key: 'totalAmount',
    special: 'money',
  },
  {
    name: '注册时间',
    key: 'registerDay'
  },
  {
    name: '最近交易',
    key: 'latestTradingTime',
    special: 'time'
  },
  {
    name: '平均客单价',
    key: 'averagePrice',
    special: 'money'
  },
];

// The order information
export const HomeOrderBtn = [
  {
    name: '发送发票',
    code: 100,
    hidden: [0, 1],
    comField: 'invoiceFlag',
    comFieldValue: 200
  },
  {
    name: '修改地址',
    code: 200,
    // hidden: [0, 3, 4, 5],
    show: [1, 2]
  },
  {
    name: '查看物流',
    code: 300,
    hidden: [0, 1, 2]
  },
  {
    name: '发起退货',
    code: 400,
    cusfield: 'isAfterSale'
  },
  {
    name: '发送订单',
    code: 500
  }
];
export const HomeOrderTab = [
  {
    name: '全部',
    key: 'allNum',
    code: 100
  },
  {
    name: '未完成',
    key: 'notFinishNum',
    code: 300
  },
  {
    name: '已完成',
    key: 'finishNum',
    code: 200
  },
  {
    name: '已关闭',
    key: 'closeNum',
    code: 400
  },
];
export const HomeOrderIcon = [
  {
    name: '刷新',
    key: 'shuaxin'
  },
  {
    name: '搜索',
    key: 'sousuo'
  },
  {
    name: '历史订单',
    key: 'lishi'
  }
];
export const HomeOrderListIcon = [
  {
    name: '详',
    code: 10,
    fullName: '订单详情'
  },
  {
    name: '票',
    key: 'invoiceFlag',
    kcode: 200,
    code: 20,
    fullName: '发票详情'
  },
  {
    name: '备注',
    key: 'sellerRemarkFlag',
    kcode: 200,
    code: 30
  }
];

//eslint-disable-next-line
export default {
  DrawerShowSource: {
    name: '来源详情',
    list: [
      {
        name: '来源渠道',
        key: 'cc_platform_name'
      },
      {
        name: '来源途径',
        key: 'cc_source_name'
      },
      {
        name: '来源机型',
        key: 'cc_model'
      },
      {
        name: '系统版本',
        key: 'cc_system'
      },
      {
        name: 'App版本',
        key: 'cc_version'
      }
    ]
  },
  DrawerShowCopy: {
    name: '实名详情',
    list: [
      {
        name: '是否实名',
        key: 'realNameStatusName'
      },
      {
        name: '真实姓名',
        key: 'realName'
      },
      {
        name: '生肖',
        key: 'bornName'
      },
      {
        name: '身份证号码',
        key: 'idNo'
      },
      {
        name: '银行卡号',
        key: 'bankCardNo'
      }
    ]
  },
  DrawerShowBase: {
    name: '修改手机记录',
    lableLeft: '最近修改前手机',
    lableRight: '修改后手机'
  },
  DrawerShowPhone: {
    name: '增值服务详情',
    lableLeft: '最近开通日期',
    lableRight: '到期日期'
  },
  DrawerShowInvoice: {
    name: '发票详情',
    list: [
      {
        name: '订单编号',
        key: 'orderCode'
      },
      {
        name: '发票金额',
        key: 'payment',
        special: 'money',
      },
      {
        name: '创建时间',
        key: 'creatTime',
        special: 'time',
      },
      {
        name: '付款时间',
        key: 'payTime',
        special: 'time',
      }
    ]
  }
};
