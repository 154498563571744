import { Input } from 'antd';
import React, { forwardRef } from 'react';

const { TextArea } = Input;

const DrawerSubRemark = (props, ref) => {
  let sellerRemark =  props.gather && props.gather._sellerRemark;
  return (
    <div className="drawer-yc-wrapper">
      <div className="m-drawer-top">
        <p className="u-p1">客服备注:</p>
      </div>
      <div className="customer-remark">
        <div>客服备注：</div>
        <div className="u-textarea">
          <TextArea ref={ref} rows={4} showCount maxLength={300} defaultValue={sellerRemark} />
        </div>
      </div>
    </div>
  )
};

export default forwardRef(DrawerSubRemark);
