import React from 'react';

import { formatDate } from '@/utils';
import { getTeaCouponList } from '@/api/home';

import HomeLoading from '@c/HomeLoading';
import HomeNull from '@c/HomeNull';
class DrawerShowTicket extends React.Component {
  constructor(props) {
    super(props);
    let { gather = {}, visible } = this.props;
    let dataLists = gather.dataTicketList || [];
    this.state = {
      tabList: [
        {
          name: '可使用',
          code: 100
        },
        {
          name: '已使用',
          code: 200
        }
      ],
      tabIndex: 0,

      dataLists,
      dataCurList: [],

      orderStatus: "",
      gather,
      visible,
      pageLoading: false
    }
    console.log('this.props ==>', this.props);
  }

  async getList(_params = {}) {
    this.setLoading(true);
    let { orderStatus, gather: { memberId = 100, platform } } = this.state;
    let params = {
      memberId,
      platform,
      orderStatus: _params.orderStatus || orderStatus,
    };
    try {
      let result = await getTeaCouponList(params);
      let dataCurList = result.filter(item => item.status === 100);
      this.setState({
        dataLists: result || [],
        dataCurList,
        pageLoading: false,
        orderStatus: params.orderStatus
      });
    } catch (error) {
      this.setLoading(false);
    }
  }

  handleTabChange(_params, _index = 0) {
    let { dataLists } = this.state;
    let dataCurList = dataLists.filter(item => item.status === _params.code);
    this.setState({
      dataCurList,
      tabIndex: _index
    });
    this._dealLoading();
  }

  componentDidMount() {
    let { visible } = this.state;
    visible && this.handleTabChange({code: 100});
  }

  setSingle(_kay, _val) {
    this.setState({
      [_kay]: _val
    })
  }

  setLoading(_val) {
    this.setState({
      pageLoading: _val
    })
  }

  _dealLoading() {
    this.setLoading(true);
    setTimeout(() => {
      this.setLoading(false);
    }, 200)
  }

  render() {
    let { tabList, tabIndex, pageLoading, dataLists, dataCurList } = this.state;

    let BoxTab = tabList.map((item, index) => {
      let num = index === tabIndex ? dataCurList.length: parseInt(dataLists.length - dataCurList.length);
      return (<span onClick={() => this.handleTabChange(item, index)} className={tabIndex === index ? 'u-cur' : ''} key={index}>{item.name}（{num}）</span>)
    });

    let BoxItem = dataCurList.map((item, index) => {
      let text = tabIndex === 0 ? '有效日期' : '使用日期';
      let time = tabIndex === 0 ? formatDate(item.invalidTime) : formatDate(item.useTime);
      return (
        <div className="m-box-item animated fadeIn" key={index}>
          <div className="m-price">{item.title}</div>
          <div className="m-vouchers">
            <div>
              <p>{item.name}</p>
              <p className="u-p1">{text} {time}</p>
            </div>
            {item.ruleDesc && <div className="u-scope">
              {item.ruleDesc}
            </div>}
          </div>
        </div>
      )
    });

    let BoxMain = BoxItem.length ? BoxItem : <HomeNull />;

    return (
      <div className="drawer-yc-wrapper">
        <div className="m-drawer-top">
          <p className="u-p1">茶票详情:</p>
        </div>
        <div className="m-drawer-tab">
          {BoxTab}
        </div>
        <div className="drawer-ticket">
          {pageLoading ? <HomeLoading /> : BoxMain}
        </div>
      </div>
    )
  }
}

export default DrawerShowTicket;
