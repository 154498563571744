// import React, { Component } from 'react';
import { Cascader } from 'antd';

// const fieldNames = {
//   label: 'name',
//   value: 'cityCodeId',
// }

const BaseCascader = (props) => {
  // const [field, setField] = React.useState(fieldNames);
  const { dataListAddress, getLoadData, onChange, initialValues, productDisplayRender } = props;

  return <Cascader
    // fieldNames={field}
    defaultValue={initialValues}
    options={dataListAddress}
    loadData={getLoadData}
    onChange={onChange}
    displayRender={productDisplayRender}
  />;
};

export default BaseCascader;
