const HomeNull = (props) => {
  let { title = "暂无数据" } = props;
  return (
    <div className="loading-wrapper">
      {title}
    </div>
  )
}

export default HomeNull;
