import { KEY_APP_KEY, APP_KEY, KEY_TOKEN, KEY_USER_ID } from '@/constant';
import { getAllQuery, setLoginInform, getLoginInform } from "@/utils/storage";
import { message } from 'antd';
import { login } from '@/api/home';

const getLogin = async () => {
  let params = getAllQuery() || {};
  let result = await login(params);
  result && setLoginInform({ ...result, customQuery: params });
  message.success('登录成功');
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export function inject(service, option) {
  // Intercept Request
  service.interceptors.request.use(
    config => {
      let isBmpAPI = config.url.indexOf('open/login') !== -1;
      let userGather = getLoginInform();
      if (isBmpAPI) {
        config.headers[KEY_APP_KEY] = APP_KEY;
      } else {
        config.headers[KEY_TOKEN] = userGather[KEY_TOKEN];
        config.headers[KEY_USER_ID] = userGather[KEY_USER_ID];;
      }
      return config;
    },
    error => {
      console.log('Request拦截器Error ==>', error);
      Promise.reject(error);
    }
  );
  // Intercept Response
  service.interceptors.response.use(
    response => {
      // console.log('response ==>', response);
      let { data } = response;
      if (data.code !== 0) {
        return errorHandler(data);
      } else {
        if (data.paging) {
          return data;
        } else {
          return data.result;
        }
      }
    },
    error => {
      console.log(JSON.stringify(error));
      return errorHandler(error)
    }
  );
}

const errorHandler = (res) => {
  let { message: msg, code } = res;
  let text = typeof res === "string" ? res : msg;

  if(code === 1009) {
    getLogin();
  } else {
    message.error(text);
  }
  console.log('errorHandler ==>', res, code);
  return Promise.reject({error: text, code});
}

export default inject;
