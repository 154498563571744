import BaseAuth from '@/config/network/BaseAuth';
import { order } from './service-base';

// 获取 会员订单列表
export function getPage(params) {
  return BaseAuth({
    url: `${order}/order/getPage`,
    method: 'POST',
    data: params
  });
}

// 获取 会员订单列表（明细）
export function getOrderPage(params) {
  return BaseAuth({
    url: `${order}/order/getOrderPage`,
    method: 'POST',
    data: params
  });
}

// 获取 订单详情
export function getOrderDetail(params) {
  return BaseAuth({
    url: `${order}/order/getOrderDetail`,
    method: 'POST',
    data: params
  });
}

// 修改订单客服备注
export function updateOrderRemark(params) {
  return BaseAuth({
    url: `${order}/order/updateOrderRemark`,
    method: 'POST',
    data: params
  });
}

// 通过订单id，查包裹列表
export function getPackageByOrderId(params) {
  return BaseAuth({
    url: `${order}/order/getPackageByOrderId`,
    method: 'POST',
    data: params
  });
}

// 通过订单id，查包裹列表
export function getOrderLogistics(params) {
  return BaseAuth({
    url: `${order}/order/getOrderLogistics`,
    method: 'POST',
    data: params
  });
}

// 通过订单id，查包裹列表
export function updateReciveAddress(params) {
  return BaseAuth({
    url: `${order}/order/updateReciveAddress`,
    method: 'POST',
    data: params
  });
}
