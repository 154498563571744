import BaseAuth from '@/config/network/BaseAuth';
import { base } from './service-base';

export function getPage(params) {
  return BaseAuth({
    url: `${base}/goods/getPage`,
    method: 'POST',
    data: params
  });
}
